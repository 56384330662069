<template>
  <div
    class="sl-swiper__wrapper"
    :class="{
      'sl-swiper__wrapper--scrolled-left': hasHorizontalScrollbar && !scrolledToLeft,
      'sl-swiper__wrapper--scrolled-right': hasHorizontalScrollbar && !scrolledToRight
    }"
  >
    <div
      ref="scrollContainer"
      class="sl-swiper"
      @wheel.prevent.stop="handleWheelScroll"
      @scroll.passive="updateScroll"
      @mousedown="handleDragStart"
      @mousemove="handleDrag"
      @mouseup="handleDragEnd"
      @mouseleave="handleDragEnd"
      @selectstart="preventTextSelection"
    >
      <slot />
    </div>

    <SlButton
      v-show="hasHorizontalScrollbar && !scrolledToLeft"
      variant="secondary"
      color="grey"
      size="xs"
      class="sl-swiper__control sl-swiper__control--prev"
      icon
      @click.stop="handleNavigation('prev')"
    >
      <icon
        data="@icons/chevron_left.svg"
        class="fill-off size-16"
      />
    </SlButton>
    <SlButton
      v-show="hasHorizontalScrollbar && !scrolledToRight"
      variant="secondary"
      color="grey"
      size="xs"
      class="sl-swiper__control sl-swiper__control--next"
      icon
      @click.stop="handleNavigation('next')"
    >
      <icon
        data="@icons/chevron_right.svg"
        class="fill-off size-16"
      />
    </SlButton>
  </div>
</template>

<script>
import { scroll } from '@/mixins/scroll';
import { customThrottle } from '@/helpers/shared/listeners';

export default {
  name: 'SlSwiperContainer',
  mixins: [scroll],
  props: {
    slidesCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      currentSlide: 0,
      isDragging: false,
      startX: 0,
      startScrollLeft: 0
    };
  },
  computed: {
    scrollContainer() {
      return this.getScrollContainer();
    },
    slideNodes() {
      return this.scrollContainer.children || [];
    }
  },
  watch: {
    slidesCount(value) {
      this.$nextTick(() => {
        if (this.currentSlide >= value) {
          this.currentSlide = value - 1;
        }

        this.updateScroll();
      });
    }
  },
  methods: {
    findClosestSlideIndex() {
      const containerRect = this.scrollContainer.getBoundingClientRect();

      const closest = Array.from(this.slideNodes).reduce((closest, child, index) => {
        const box = child.getBoundingClientRect();
        const offsetLeft = box.left - containerRect.left + this.scrollLeft;
        const distance = offsetLeft - this.scrollLeft;

        if (Math.abs(distance) < Math.abs(closest.distance)) {
          return { index, distance };
        }

        return closest;
      }, { index: 0, distance: Infinity });

      return closest.index;
    },
    slideToClosest() {
      const children = Array.from(this.slideNodes);

      if (!children.length) {
        return;
      }

      if (this.scrolledToLeft) {
        this.currentSlide = 0;

        return this.scrollTo(children[this.currentSlide].offsetLeft);
      }

      if (this.scrolledToRight) {
        this.currentSlide = this.slidesCount - 1;

        return this.scrollTo(children[this.currentSlide].offsetLeft);
      }

      this.currentSlide = this.findClosestSlideIndex();

      this.scrollTo(children[this.currentSlide].offsetLeft);
    },
    slidePrev() {
      if (this.scrolledToLeft) {
        return;
      }

      let prevSlideIndex = this.currentSlide - 1;

      if (this.scrolledToRight) {
        const closestSlideIndex = this.findClosestSlideIndex();

        prevSlideIndex = this.slideNodes[closestSlideIndex].offsetLeft > this.scrollLeft
          ? closestSlideIndex - 1
          : closestSlideIndex;
      }

      this.currentSlide = Math.max(0, prevSlideIndex);
      this.scrollTo(this.slideNodes[this.currentSlide].offsetLeft);
    },
    slideNext() {
      if (this.scrolledToRight) {
        return;
      }

      this.currentSlide = Math.min(this.slidesCount - 1, this.currentSlide + 1);

      this.scrollTo(this.slideNodes[this.currentSlide].offsetLeft);
    },
    scrollTo(left) {
      requestAnimationFrame(() => {
        this.scrollContainer.scrollTo({
          left,
          behavior: 'smooth'
        });
      });
    },
    throttledUpdateScroll: customThrottle(function(e) {
      const delta = e.deltaY || e.wheelDelta;

      return delta > 0 ? this.handleNavigation('next') : this.handleNavigation('prev');
    }, 150),
    handleNavigation(direction) {
      if (!this.scrollContainer) {
        return;
      }

      if (direction === 'prev') {
        return this.slidePrev();
      }

      if (direction === 'next') {
        return this.slideNext();
      }
    },
    handleWheelScroll(e) {
      requestAnimationFrame(() => this.throttledUpdateScroll(e));
    },
    handleDragStart(e) {
      this.isDragging = true;

      this.startX = e.pageX - this.scrollContainer.offsetLeft;
      this.startScrollLeft = this.scrollContainer.scrollLeft;
    },
    handleDrag(e) {
      if (!this.isDragging) {
        return;
      }

      e.preventDefault();

      const x = e.pageX - this.scrollContainer.offsetLeft;
      const walk = x - this.startX;

      this.scrollContainer.scrollLeft = this.startScrollLeft - walk;
      this.updateScroll();
    },
    handleDragEnd() {
      if (this.isDragging) {
        this.slideToClosest();
      }

      this.isDragging = false;
    },
    preventTextSelection(e) {
      if (this.isDragging) {
        e.preventDefault();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-swiper/sl-swiper-container";
</style>