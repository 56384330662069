<template>
  <div class="pending-tasks">
    <transition name="fade">
      <SlButton
        v-if="applyingOverrides"
        variant="tertiary"
        class="pending-tasks__item"
      >
        <template #prepend>
          <SlLoader
            size="3xs"
            :text="false"
            inline
          />
        </template>
        {{ $t('Web.ApplyingOverrides') }}
      </SlButton>
    </transition>
    <transition name="fade">
      <SlButton
        v-if="projectSaving"
        variant="tertiary"
        class="pending-tasks__item"
      >
        <template #prepend>
          <SlLoader
            size="3xs"
            :text="false"
            inline
          />
        </template>
        {{ $t('Web.SavingProject') }}
      </SlButton>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { namespaceByRoute } from '@/config/report';

export default {
  name: 'PendingTasks',
  data() {
    return {
      applyingOverrides: false,
      applyOverridesTimeout: null,
      applyOverridesDelay: 500,
      projectSaving: false,
      projectSaveTimeout: null,
      projectSaveDelay: 500
    };
  },
  computed: {
    ...mapState({
      isDemandNodeUpdating: (state) => state.demand.is_node_updating,
      isProjectSaving: (state) => state.project.is_saving
    }),
    isReportTableUpdating() {
      return this.$store.state[namespaceByRoute[this.$sl_routeName]]?.is_updating;
    },
    isOverriding() {
      return this.isDemandNodeUpdating || this.isReportTableUpdating;
    }
  },
  watch: {
    isOverriding(isOverriding) {
      if (isOverriding) {
        this.applyOverridesTimeout = setTimeout(() => {
          this.applyingOverrides = true;
        }, this.applyOverridesDelay);
      } else {
        clearTimeout(this.applyOverridesTimeout);
        this.applyingOverrides = false;
      }
    },
    isProjectSaving(isProjectSaving) {
      if (isProjectSaving) {
        this.projectSaveTimeout = setTimeout(() => {
          this.projectSaving = true;
        }, this.projectSaveDelay);
      } else {
        clearTimeout(this.projectSaveTimeout);
        this.projectSaving = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils";

.pending-tasks {
  position: absolute;
  bottom: 20px;
  right: 20px;
  // vue-notification-group has 5000 z-index
  z-index: 4000;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .pending-tasks__item {
    width: 100%;
    justify-content: start;

    background: $white;
    border-radius: 20px;
    box-shadow: 0 2px 4px -2px #1219260F, 0 5px 12px -3px #1219261F, 0 0 6px 0 #1219261A;
  }
}
</style>