<template>
  <div class="sl-upload-link">
    <SlInput
      v-model="vModel"
      :placeholder="placeholder"
      :disabled="isUploadSuccess || uploading"
      :is-invalid="isInvalid || isUploadError"
      :max-length="9999"
      v-bind="$attrs"
      v-on="bindListeners"
      @keydown.enter.native="handleUpload"
    >
      <template #prepend>
        <slot name="prepend" />
      </template>

      <template #append>
        <slot name="append" />
      </template>
    </SlInput>
    <div class="sl-upload-link__actions">
      <SlButton
        variant="secondary"
        :color="uploadBtnColor"
        :disabled="uploading || isUploadSuccess"
        class="sl-upload-link__button"
        icon
        @click="handleUpload"
      >
        {{ $t('Web.FileUpload.Upload') }}
        <template
          v-if="isUploadStateVisible"
          #loader
        >
          <transition name="fade">
            <icon
              v-if="isUploadError"
              data="@icons/error_circle.svg"
              class="fill-off size-20 color-accent-red-90 sl-upload-link__state"
            />
          </transition>
          <transition name="fade">
            <icon
              v-if="isSuccessIconVisible"
              data="@icons/success.svg"
              class="fill-off size-20 color-primary-80 sl-upload-link__state"
            />
          </transition>
          <transition name="fade">
            <SlOverlay
              :show="uploading"
              size="xxs"
              filled
              :text="false"
              class="sl-upload-link__state"
            />
          </transition>
        </template>
      </SlButton>
      <SlButton
        variant="secondary"
        color="grey"
        :disabled="uploading"
        class="sl-upload-link__button"
        icon
        @click="handleRemove"
      >
        <icon
          data="@icons/trash.svg"
          class="size-20 fill-off"
        />
      </SlButton>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'SlUploadLink',
  props: {
    value: {
      type: String,
      default: ''
    },
    uploadCallback: {
      type: Function,
      required: true
    },
    validator: {
      type: Function,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    isInvalid: Boolean,
    uploaded: Boolean
  },
  data() {
    return {
      source: null,
      uploadAttemp: false,
      uploading: false,
      successful: false,
      showSuccessfulIcon: false,
      showSuccessTimeout: 2000
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);

        this.uploadAttemp = false;
      }
    },
    isUploadError() {
      return this.uploadAttemp && !this.uploading && !this.successful;
    },
    isUploadSuccess() {
      return this.uploadAttemp && !this.uploading && this.successful;
    },
    isSuccessIconVisible() {
      return this.isUploadSuccess && this.showSuccessfulIcon;
    },
    isUploadStateVisible() {
      return this.uploading || this.isUploadError || this.isSuccessIconVisible;
    },
    bindListeners() {
      const { input, ...rest } = this.$listeners;

      return rest;
    },
    uploadBtnColor() {
      return this.isUploadError ? 'accent-red' : 'primary';
    }
  },
  watch: {
    successful(val) {
      if (!val) {
        return;
      }

      this.showSuccessfulIcon = true;

      setTimeout(() => {
        this.showSuccessfulIcon = false;
      }, this.showSuccessTimeout);
    }
  },
  created() {
    this.successful = this.uploaded;
    this.uploadAttemp = this.uploaded;
  },
  methods: {
    handleUploadError() {
      this.uploading = false;
      this.successful = false;
    },
    async handleUpload() {
      if (this.validator) {
        const { valid } = await this.validator();

        if (!valid) {
          return;
        }
      }

      try {
        this.$emit('upload-start');

        this.uploadAttemp = true;
        this.uploading = true;

        const CancelToken = axios.CancelToken;

        this.source = CancelToken.source();

        const response = await this.uploadCallback({
          value: this.vModel,
          reqConfig: {
            cancelToken: this.source.token
          }
        });

        if (!response) {
          return this.handleUploadError();
        }

        this.uploading = false;
        this.successful = true;

        this.$emit('upload-success', { url: this.vModel, response });
      } catch (e) {
        e.message = e.message?.split('\n').shift();

        this.$emit('upload-error', e);

        this.handleUploadError();
      } finally {
        this.source = null;
        this.$emit('upload-end');
      }
    },
    handleRemove() {
      this.$emit('remove', () => {
        if (this.uploading && this.source) {
          this.source.cancel();
        }

        this.uploadAttemp = false;
        this.uploading = false;
        this.successful = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-upload-link";
</style>
