import QBApi from '@/api/integrations/qb';

const types = {
  SET_ACCOUNTS: 'SET_ACCOUNTS',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  accounts: []
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_ACCOUNTS](state, value) {
    state.accounts = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  setAccounts({ commit }, payload) {
    commit(types.SET_ACCOUNTS, payload);
  },
  fetchAccounts(_, id) {
    return QBApi.getAccounts({ id });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
