<template>
  <button
    v-tooltip.bottom="getTooltip({
      content: $t('Web.FreeVersion.AvailableIn'),
      disabled: !disabled
    })"
    class="integration-item"
    :disabled="disabled"
    @click="$emit('select', integration)"
  >
    <span class="integration-item__icon-wrapper">
      <span class="integration-item__icon">
        <icon
          :data="getIconByType(integration.type)"
          :class="integrationClasses"
        />
      </span>
    </span>
    <span class="integration-item__name body-3-md grey-60">
      {{ integration.name }}
    </span>
    <icon
      v-if="disabled"
      data="@icons/upgrade-plan.svg"
      class="fill-off size-16 color-grey-50 integration-item__upgrade-icon"
    />
  </button>
</template>

<script>
import { integrationsConfig, integrationTypes } from '@/config/integrations';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'IntegrationTile',
  props: {
    integration: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  computed: {
    isCustomApi() {
      return this.integration.type === integrationTypes.CUSTOM_API;
    },
    integrationClasses() {
      return ['size-40', this.isCustomApi ? 'fill-off' : ''];
    }
  },
  methods: {
    getTooltip,
    getIconByType(type) {
      return integrationsConfig[type]?.icon;
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/style/components/integrations/integration-item.scss';
</style>
