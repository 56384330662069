import { routeNames } from '@/config/router/router.config';

const reportRoutes = [
  {
    path: ':projectId/inventory/',
    name: routeNames.INVENTORY,
    props: true,
    component: () => import(/* webpackPrefetch: true */ '../views/Report.vue')
  },
  {
    path: ':projectId/intersite/',
    name: routeNames.INTERSITE,
    component: () => import(/* webpackPrefetch: true */ '../views/Report.vue')
  },
  {
    path: ':projectId/reports/',
    name: routeNames.REPORTS,
    props: true,
    component: () => import(/* webpackPrefetch: true */ '../views/Report.vue')
  },
  {
    path: ':projectId/inventory/containers',
    name: routeNames.COLLECTIONS,
    component: () => import(/* webpackPrefetch: true */ '../views/SubPages/Collections.vue')
  },
  {
    path: ':projectId/inventory/planned-orders',
    name: routeNames.INVENTORY_PLANNED_ORDERS,
    component: () => import(/* webpackPrefetch: true */ '../views/SubPages/PlannedOrders.vue')
  },
  {
    path: ':projectId/inventory/constrained-plan',
    name: routeNames.CONSTRAINED_PLAN,
    component: () => import(/* webpackPrefetch: true */ '../views/SubPages/ConstrainedPlan.vue')
  },
  {
    path: ':projectId/intersite/planned-orders',
    name: routeNames.INTERSITE_PLANNED_ORDERS,
    component: () => import(/* webpackPrefetch: true */ '../views/SubPages/PlannedOrders.vue')
  },
  {
    path: ':projectId/inventory/in-transition',
    name: routeNames.INVENTORY_IN_TRANSITION,
    component: () => import(/* webpackPrefetch: true */ '../views/SubPages/InTransition.vue')
  },
  {
    path: ':projectId/intersite/in-transition',
    name: routeNames.INTERSITE_IN_TRANSITION,
    component: () => import(/* webpackPrefetch: true */ '../views/SubPages/InTransition.vue')
  },
  {
    path: ':projectId/intersite/transfer-regions',
    name: routeNames.TRANSFER_REGIONS,
    component: () => import(/* webpackPrefetch: true */ '../views/SubPages/TransferRegions.vue')
  }
];

export default reportRoutes;
