import { routeNames } from '@/config/router/router.config';

export const uiTabKeys = {
  DEMAND: 'Demand',
  INVENTORY: 'Inventory',
  REPORTS: 'Reports',
  INTERSITE: 'Intersite',
  GLOBAL: 'Global'
};

export const uiTabByRoute = {
  [routeNames.DEMAND]: uiTabKeys.DEMAND,
  [routeNames.INVENTORY]: uiTabKeys.INVENTORY,
  [routeNames.REPORTS]: uiTabKeys.REPORTS,
  [routeNames.INTERSITE]: uiTabKeys.INTERSITE
};

export const uiSettingsKeys = {
  COLUMN_PIN: 'pinnedColumns',
  COLUMNS_WIDTH: 'columnsWidth',
  SPECIFIC: 'specific',
  DEMAND_TABLE_HEIGHT: 'demandTableHeight',
  DEMAND_TREE_WIDTH: 'demandTreeWidth',
  DEMAND_SETTINGS_WIDTH: 'demandSettingsWidth'
};
