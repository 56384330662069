export const validateLocales = {
  en: 'en',
  es: 'es',
  fr: 'fr',
  ja: 'ja',
  pl: 'pl',
  pt: 'pt_BR',
  ru: 'ru',
  tr: 'tr',
  zh: 'zh_CN',
  uk: 'uk',
  cs: 'cs',
  de: 'de',
  fa: 'fa',
  it: 'it',
  ko: 'ko'
};
