export const updateRequestMethods = ['post', 'put', 'patch', 'delete'];

export const ignoredRequests = ['/time_machine', '/manage_projects/open_example'];

export const manageProjectRequest = 'manage_projects';

export const bypassPendingRequests = [
  '/spreadsheet/upload',
  '/integrations/spreadsheet/file/upload'
];

export const pingRequests = [
  // operations requests
  '/operations',
  '/operations/data',
  '/operations/project_data',
  // other requests
  '/manage_projects/list',
  '/ram_stats',
  '/last_change'
];
