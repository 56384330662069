import { i18n } from '@/plugins/vue-i18n.plugin';

export const curveKeys = {
  ACTUAL_SALES: 'actual',
  LOWER_ECHELON_DEMAND: 'lowerEchelonDemand',
  MATERIAL_CONSUMPTION: 'materialConsumption',
  FORECAST_LEARN: 'learn',
  FORECAST_PREDICTED: 'predicted',
  SAFETY_STOCK: 'safestock',
  PROJECTED_INVENTORY: 'remainder',
  CONF_LIMIT: 'conflimit',
  PROMOTIONS: 'promo',
  SALES_ORDERS_FORECAST: 'salesOrdersForecast'
};

export const chartTabKeys = {
  QTY: 'quantity',
  REVENUE: 'revenue'
};

export const chartTabOptions = [
  {
    label: i18n.tc('Main.ChartQty'),
    value: chartTabKeys.QTY
  },
  {
    label: i18n.tc('Main.ChartValue'),
    value: chartTabKeys.REVENUE
  }
];

export const datasetsConfig = (modelTypeName) => ({
  [curveKeys.ACTUAL_SALES]: {
    title: {
      [chartTabKeys.QTY]: i18n.tc('Main.Chart.LegActual'),
      [chartTabKeys.REVENUE]: i18n.tc('Main.Chart.LegRevenue')
    },
    color: {
      primary: '#9DA4AE',
      colorblind: 'rgb(240, 228, 66)'
    },
    legendIndex: 1,
    order: 3
  },
  [curveKeys.SAFETY_STOCK]: {
    title: i18n.tc('Main.Chart.LegSafeStock'),
    color: {
      primary: '#FECDCA80',
      colorblind: 'rgb(204, 121, 167)'
    },
    legendIndex: 2,
    order: 9
  },
  [curveKeys.PROJECTED_INVENTORY]: {
    title: i18n.tc('Main.Chart.LegInventory'),
    color: {
      primary: '#099250',
      colorblind: 'rgb(0, 158, 115)'
    },
    legendIndex: 3,
    order: 5
  },
  [curveKeys.LOWER_ECHELON_DEMAND]: {
    title: i18n.tc('Main.Chart.LegLowerEchelonDemand'),
    color: {
      primary: '#FFD700',
      colorblind: '#0066CC'
    },
    legendIndex: 4,
    order: 6
  },
  [curveKeys.MATERIAL_CONSUMPTION]: {
    title: i18n.tc('Main.Chart.LegMaterialConsumption'),
    color: {
      primary: 'rgb(170, 51, 106)',
      colorblind: '#000000'
    },
    legendIndex: 5,
    order: 7
  },
  [curveKeys.CONF_LIMIT]: {
    title: i18n.tc('Main.Chart.LegConfLimits'),
    color: {
      primary: '#7F56D9',
      colorblind: 'rgb(86, 114, 179)'
    },
    legendIndex: 6,
    order: 8
  },
  [curveKeys.PROMOTIONS]: {
    title: i18n.tc('Main.Chart.Promotions'),
    color: {
      primary: '#FDB022',
      colorblind: '#f7b529'
    },
    legendIndex: 7,
    order: 1
  },
  [curveKeys.FORECAST_LEARN]: {
    title: i18n.tc('Main.Chart.LegTraining'),
    color: {
      primary: '#155EEF',
      colorblind: 'rgb( 86, 180, 233)'
    },
    legendIndex: 8,
    order: 2
  },
  [curveKeys.FORECAST_PREDICTED]: {
    title: modelTypeName
      ? i18n.t('Main.Chart.LegPredict2', { 1: modelTypeName })
      : i18n.tc('Main.Chart.LegPredict'),
    color: {
      primary: '#ED4132',
      colorblind: '#000000'
    },
    legendIndex: 10,
    layoutIndex: 4
  },
  [curveKeys.SALES_ORDERS_FORECAST]: {
    title: i18n.tc('Main.Chart.LegSalesOrdersForecast'),
    color: {
      primary: '#F2966A80',
      colorblind: '#56B4E9'
    },
    legendIndex: 9,
    layoutIndex: 10
  }
});

export const chartTooltipsConfig = {
  [curveKeys.ACTUAL_SALES]: (val, tab) => tab === chartTabKeys.QTY
    ? i18n.tc('Main.Chart.Actual', null, { 1: val })
    : i18n.tc('Main.Chart.Revenue', null, { 1: val }),
  [curveKeys.FORECAST_LEARN]: (val) => i18n.tc('Main.Chart.Training', null, { 1: val }),
  [curveKeys.FORECAST_PREDICTED]: (val) => i18n.tc('Main.Chart.Predicted', null, { 1: val }),
  [curveKeys.SAFETY_STOCK]: (val) => i18n.tc('Main.Chart.SafeStock', null, { 1: val }),
  [curveKeys.PROJECTED_INVENTORY]: (val) => i18n.tc('Main.Chart.Inventory', null, { 1: val }),
  upperConfLimit: (val) => i18n.tc('Main.Chart.UpperConfLimit', null, { 1: val }),
  lowerConfLimit: (val) => i18n.tc('Main.Chart.LowerConfLimit', null, { 1: val }),
  [curveKeys.SALES_ORDERS_FORECAST]: (val) => i18n.tc('Main.Chart.SalesOrdersForecast', null, { 1: val })
};