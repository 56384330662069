var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['sl-select-wrapper', ( _obj = {
    'sl-select--inline': _vm.inline,
    'sl-select--loading': _vm.loading,
    'sl-select--clearable': _vm.isClearable
  }, _obj[("sl-select--" + _vm.size)] = _vm.size, _obj )],on:{"mousedown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }$event.preventDefault();$event.stopPropagation();return _vm.toggle($event)}}},[(_vm.selectLabel)?_c('div',{staticClass:"sl-select__label"},[_c('div',{class:[{
        'sl-required': _vm.required
      }, 'sl-select__label-title body-1-md grey-80' ]},[_vm._v(" "+_vm._s(_vm.selectLabel)+" "),(_vm.$scopedSlots.icon)?_vm._t("icon"):_vm._e()],2),_c('div',{staticClass:"sl-select__label-description body-3 grey-60"},[_vm._v(" "+_vm._s(_vm.selectLabelDescr)+" ")])]):_vm._e(),_c('Multiselect',{directives:[{name:"select-overflow",rawName:"v-select-overflow",value:({
      appendToBody: _vm.appendToBody,
      optionSelector: _vm.appendToBodyOptionSelector,
      openDirection: _vm.openDirection,
      menuZIndex: _vm.menuZIndex
    }),expression:"{\n      appendToBody,\n      optionSelector: appendToBodyOptionSelector,\n      openDirection,\n      menuZIndex\n    }"},{name:"select-scroll",rawName:"v-select-scroll",value:(_vm.scrollCallback),expression:"scrollCallback"}],ref:"multiselect",staticClass:"sl-select",class:{
      'sl-select--invalid': _vm.isInvalid,
      'sl-select--disabled': _vm.localDisabled,
      'sl-select--multiple': _vm.multiple,
      'sl-select--tagged': _vm.multiple && _vm.selectValue.length,
    },attrs:{"value":_vm.selectValue,"options":_vm.options,"track-by":_vm.localTrackBy,"label":_vm.localLabel,"placeholder":"","disabled":_vm.localDisabled,"multiple":_vm.multiple,"taggable":_vm.taggable,"searchable":_vm.searchable,"group-values":_vm.groupValues,"group-label":_vm.groupLabel,"group-select":_vm.groupSelect,"close-on-select":_vm.closeOnSelect,"clear-on-select":_vm.clearOnSelect,"max-height":_vm.maxHeight,"allow-empty":_vm.allowEmpty,"show-no-options":_vm.showNoOptions,"internal-search":_vm.internalSearch,"preserve-search":_vm.preserveSearch,"show-labels":false,"show-pointer":false},on:{"open":function($event){return _vm.$emit('open')},"close":function($event){return _vm.$emit('close')},"remove":function (option, id) { return _vm.$emit('remove', option, id); },"input":_vm.setSelected,"search-change":_vm.searchChange},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope || {})]}}}),{key:"tag",fn:function(ref){
    var option = ref.option;
    var remove = ref.remove;
    var search = ref.search;
return [(_vm.$scopedSlots.tag)?_vm._t("tag",null,null,{
          option: option,
          remove: function () { return _vm.clearTag(option, remove); },
          search: search
        }):_c('SlTag',{attrs:{"clearable":""},on:{"clear":function($event){return _vm.clearTag(option, remove)}}},[_vm._v(" "+_vm._s(option[_vm.localLabel] || option)+" ")])]}},{key:"placeholder",fn:function(){return [_vm._v(" "+_vm._s(_vm.localPlaceholder)+" ")]},proxy:true},{key:"caret",fn:function(ref){
        var toggle = ref.toggle;
return [_c('div',{staticClass:"sl-select__caret-wrapper"},[(_vm.$scopedSlots['prepend-caret'])?_vm._t("prepend-caret"):_vm._e(),(_vm.isClearable)?[(_vm.$scopedSlots.clear)?_vm._t("clear",null,null,{
              clear: _vm.clearAll
            }):_c('SlButton',{staticClass:"sl-select__clear",attrs:{"size":"xs","variant":"tertiary","color":"grey","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.clearAll($event)}}},[_c('icon',{staticClass:"size-16",attrs:{"data":require("@icons/close.svg")}})],1)]:_vm._e(),(_vm.updating)?_c('SlLoader',{attrs:{"size":"3xs","text":false,"inline":""}}):_c('icon',{staticClass:"sl-select__caret fill-off size-16",attrs:{"data":require("@icons/chevron_down.svg")},on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return toggle($event)}}}),(_vm.$scopedSlots['append-caret'])?_vm._t("append-caret"):_vm._e()],2)]}},{key:"clear",fn:function(){return undefined},proxy:true},{key:"option",fn:function(ref){
            var option = ref.option;
return [(_vm.$scopedSlots.selectOption)?_vm._t("selectOption",null,null,{
          option: option,
          selected: _vm.isOptionSelected(option),
          toggle: _vm.toggle
        }):[_c('span',{class:{
            'sl-select__option--special': option.isSpecial
          }},[_vm._v(" "+_vm._s(option[_vm.localLabel] || option)+" ")]),(_vm.isOptionSelected(option))?_c('icon',{staticClass:"fill-off size-16 color-primary-80 check-icon",attrs:{"data":require("@icons/check.svg")}}):_vm._e()]]}},{key:"beforeList",fn:function(){return [_c('SlOverlay',{attrs:{"show":_vm.loading,"filled":""}},[_c('SlLoader',{attrs:{"size":"xs","text":_vm.$t('Web.Loading.LoadingData'),"inline":""}})],1),_vm._t("default")]},proxy:true},{key:"noOptions",fn:function(){return [_c('div',{staticClass:"sl-select__additional-info"},[(_vm.$scopedSlots.noOptions)?_vm._t("noOptions"):[_vm._v(" "+_vm._s(_vm.$t('Web.Select.NoOptions'))+" ")]],2)]},proxy:true},{key:"noResult",fn:function(){return [_c('div',{staticClass:"sl-select__additional-info"},[(_vm.$scopedSlots.noResult)?_vm._t("noResult"):(!_vm.loading)?[_vm._v(" "+_vm._s(_vm.$t('Web.Select.NoSearchResults'))+" ")]:_vm._e()],2)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }