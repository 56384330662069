import { noAccessReasons } from '@/config/router/noAccess.config';
import { routeNames } from '@/config/router/router.config';
import { projectRedirect } from '@/helpers/router';
import { isUserAdmin } from '@/mixins/frontegg';

export default async function access({ to, next, nextMiddleware, store }) {
  if (to.query.reason) {
    return next();
  }

  const isGracePeriodFinished = store.getters['account/isGracePeriodFinished'];

  if (isGracePeriodFinished) {
    return next({
      name: routeNames.NO_ACCESS,
      query: {
        reason: noAccessReasons.LICENSE_EXPIRED
      }
    });
  }

  const availableMainRoutes = store.getters['access/availableMainRoutes'];

  if (!availableMainRoutes?.length) {
    const isAdmin = isUserAdmin();
    const nextName = isAdmin ? routeNames.GET_STARTED : routeNames.NO_ACCESS;

    return next({
      name: nextName
    });
  }

  const isRouteAvailable = store.getters['access/isRouteAvailable'](to.name);

  if (!isRouteAvailable) {
    return projectRedirect({
      name: availableMainRoutes[0]
    });
  }

  return nextMiddleware();
}
