import { namespaceByRoute } from '@/config/report';
import { filterPageTypes } from '@/config/filters';
import { routeNames } from '@/config/router/router.config';

export default [
  'sidebar',
  'manageProjects.projectId',
  'manageProjects.filters',
  'demand.tree.active_node_path',
  'demand.tree.order',
  'demand.tree.abc_analysis',
  'demand.tree.categories',
  'demand.tree.orders',
  'demand.tree.hide_inactive',
  'demand.tree.is_filters_opened',
  'forecastReport.requestParams',
  'forecastReport.aggregation',
  'inventoryReport.inTransition.is_edited',
  'inventoryReport.inTransition.tab',
  'inventoryReport.collections.is_edited',
  'inventoryReport.collections.tab',
  'inventoryReport.plannedOrders.has_edited',
  'inventoryReport.plannedOrders.tab',
  'substitutions.is_edited',
  'substitutions.tab',
  'workspace.id',
  `${namespaceByRoute[routeNames.INVENTORY]}.tableConfig`,
  `${namespaceByRoute[routeNames.INTERSITE]}.tableConfig`,
  `${namespaceByRoute[routeNames.REPORTS]}.tableConfig`,
  `filters.${filterPageTypes.DEMAND}.active_filter_id`,
  `filters.${filterPageTypes.INVENTORY}.active_filter_id`,
  `filters.${filterPageTypes.INTERSITE}.active_filter_id`,
  `filters.${filterPageTypes.REPORT}.active_filter_id`,
  'changes.tableConfig',
  'spreadsheet.files_source',
  'spreadsheet.files',
  'spreadsheet.commonData',
  'connection.selectedConnector',
  'connection.connectionData',
  'connection.sourceConnectData',
  'connection.connection_data',
  'bc365.environments',
  'bc365.companies',
  'skubana',
  'dbRelated.cache_id',
  'dbRelated.connection_data',
  'qb',
  'integrations.group_by',
  'integrations.active_integration',
  'integrations.active_steps',
  'integrations.is_edited',
  'integrations.settings',
  'integrations.spreadsheet.tab',
  'integrations.spreadsheet.files',
  'integrations.spreadsheet.settings',
  'integrations.spreadsheet.match_sheets',
  'bom.selected_entry',
  'bom.open_nodes',
  'dashboard.tab'
];
