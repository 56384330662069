import Vue from 'vue';
import userApi from '@/api/user';
import uiSettingsApi from '@/api/uiSettings';
import logger from '@/api/logger';
import { ContextHolder } from '@frontegg/vue';
import { routeNames } from '@/config/router/router.config';
import { uiTabByRoute, uiSettingsKeys, uiTabKeys } from '@/config/users/uiSettings.config';
import { VUEX_LS_KEY } from '@/config/app.config';
import { setLanguageHeader } from '@/helpers/api/axios';
import { getRouteName } from '@/helpers/shared/router';

const types = {
  SET_USER_INFO: 'SET_USER_INFO',
  RESET_STATE: 'RESET_STATE',
  SET_UI_SETTINGS: 'SET_UI_SETTINGS'
};

const defaultState = () => ({
  userInfo: null,
  ui_settings: {}
});

const state = defaultState();

const getters = {
  userRole: (state) => state.userInfo?.roleName || '',
  currentUiSettings: (state) => state.ui_settings[uiTabByRoute[getRouteName()]] || {},
  getCurrentUiTab: () => (tab) => {
    const routeName = getRouteName();
    const isValidTab = Object.values(uiTabKeys).includes(tab);

    return isValidTab ? tab : uiTabByRoute[routeName] || uiTabKeys.GLOBAL;
  }
};

const mutations = {
  [types.SET_USER_INFO](state, value) {
    state.userInfo = value;
  },
  [types.SET_UI_SETTINGS](state, { key, value }) {
    Vue.set(state.ui_settings, key, value);
  },
  [types.RESET_STATE](state) {
    const initialState = defaultState();

    Object.keys(state).forEach(key => {
      state[key] = initialState[key];
    });
  }
};

const actions = {
  async logout(_, payload) {
    if (payload?.withRedirect) {
      const baseUrl = ContextHolder.getContext().baseUrl;

      window.location.replace(`${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`);

      return localStorage.removeItem(VUEX_LS_KEY);
    }

    this.dispatch('timeMachine/reset');

    if (payload?.e && !payload.e.isAxios) {
      logger.formatAndWriteError(payload);
    }
  },
  async fetchUserInfo({ commit, dispatch, rootState }) {
    try {
      setLanguageHeader(rootState.settings.language);

      const response = await userApi.getUserInfo();
      const data = response?.data;

      if (!data) {
        return;
      }

      const { user, role } = data;

      commit(types.SET_USER_INFO, user);
      this.dispatch('access/setUserPermissions', role);
    } catch (e) {
      dispatch('logout', { e, from: 'fetchUserInfo' });
      throw e;
    }
  },
  async reportIssue(_, { username, email, userIssue, files, needLogs }) {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('file', file, encodeURIComponent(file.name));
    });

    await userApi.reportIssue({
      username,
      email,
      issue: userIssue,
      needLogs,
      formData
    });
  },
  async fetchUiSettings({ getters, dispatch, commit }, uiTab) {
    const routeName = getRouteName();
    const tab = getters.getCurrentUiTab(uiTab);

    try {
      const response = await uiSettingsApi.getUiSettings({ tab });
      const data = response?.data;

      if (!data) {
        return;
      }

      commit(types.SET_UI_SETTINGS, {
        key: tab,
        value: data
      });

      if (routeName === routeNames.DEMAND) {
        this.dispatch('demand/table/setResizedColumns', data[uiSettingsKeys.COLUMNS_WIDTH] || []);
      }
    } catch (e) {
      dispatch('logout', { e, from: 'fetchUiSettings' });
    }
  },
  async changeUiSettings({ getters, dispatch }, { uiTab, key, value }) {
    const tab = getters.getCurrentUiTab(uiTab);

    try {
      await uiSettingsApi.changeUiSettings(value, { tab, key });
    } catch (e) {
      dispatch('logout', { e, from: 'changeUiSettings' });
    }
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
