<template>
  <div
    class="sl-dropzone-wrapper"
    :class="{
      'sl-dropzone-wrapper--disabled': localDisabled,
      'sl-dropzone-wrapper--invalid': isInvalid
    }"
  >
    <div
      v-if="label || description"
      class="sl-dropzone-header"
    >
      <div
        v-if="label"
        class="sl-dropzone-header__label"
      >
        {{ label }}
      </div>
      <div
        v-if="description"
        class="sl-dropzone-header__description"
      >
        {{ description }}
      </div>
    </div>
    <div
      v-tooltip="{
        theme: 'dark',
        content: $t('Web.FileUpload.TooltipMaxFiles', { 1: tooltipMaxFiles || maxFiles }),
        disabled: !isMaxFilesReached
      }"
      class="sl-dropzone__input-wrapper"
    >
      <vue-dropzone
        :id="id"
        ref="slDropzone"
        class="sl-dropzone"
        :options="dropzoneOptions"
        use-custom-slot
        @vdropzone-file-added="onFileAdded"
        @vdropzone-removed-file="onFileRemoved"
      >
        <slot
          v-if="$scopedSlots.content"
          name="content"
        />
        <div
          v-else
          class="sl-dropzone__content"
        >
          <div class="sl-dropzone__content-icon">
            <icon
              v-if="!isInvalid"
              data="@icons/style_upload_cloud_double.svg"
              class="fill-off size-40"
            />
            <icon
              v-else
              data="@icons/style_error_double.svg"
              class="fill-off size-40"
            />
          </div>
          <div class="sl-dropzone__content-label">
            <SlButton
              variant="text"
              color="grey"
              :disabled="localDisabled"
            >
              {{ $t('Web.FileUpload.ClickToUpload') }}
            </SlButton>
            {{ $t('Web.FileUpload.DragAndDrop') }}
          </div>
          <div class="sl-dropzone__accepted">
            <slot
              v-if="$scopedSlots.accepted"
              name="accepted"
            />
          </div>
        </div>
      </vue-dropzone>
    </div>
    <div class="sl-dropzone__hidden-previews dropzone-previews" />
    <transition name="fade-down">
      <div
        v-if="uploads.length"
        class="sl-dropzone__previews"
      >
        <SlSwiperContainer :slides-count="uploads.length">
          <SlSwiperSlide
            v-for="({ file, uploading, successful, error }, index) in uploads"
            :key="file && file.upload.uuid"
          >
            <SlDropzonePreviewItem
              :file="file"
              :uploading="uploading"
              :successful="successful"
              :error="error"
              @remove="handleRemoveFile"
              @reupload="(file) => handleReUploadFile(file, index)"
            />
          </SlSwiperSlide>
        </SlSwiperContainer>
      </div>
    </transition>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import axios from 'axios';

export default {
  name: 'SlDropzone',
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    files: {
      type: [Array, null],
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    accepted: {
      type: String,
      default: ''
    },
    maxFiles: {
      type: Number,
      default: 1
    },
    tooltipMaxFiles: {
      type: Number,
      default: null
    },
    // if props used callback must return response
    // and take reqConfig with cancel token
    uploadCallback: {
      type: Function,
      default: null
    },
    validator: {
      type: Function,
      default: null
    },
    disabled: Boolean,
    isInvalid: Boolean,
    removeConfirm: Boolean
  },
  data() {
    return {
      uploads: [],
      manualDisabled: false,
      dropzoneOptions: {
        url: '#',
        thumbnailWidth: 150,
        maxFilesize: this.maxFiles,
        autoProcessQueue: false,
        previewsContainer: '.sl-dropzone__hidden-previews',
        hiddenInputContainer: '.sl-dropzone-wrapper',
        acceptedFiles: this.accepted
      },
      // prevent call removed-file https://github.com/rowanwins/vue-dropzone/issues/424
      isDestroying: false
    };
  },
  computed: {
    id() {
      return `sl-dropzone-${this._uid}`;
    },
    localDisabled() {
      return this.disabled || this.manualDisabled || this.isMaxFilesReached;
    },
    isMaxFilesReached() {
      return this.uploads.length >= this.maxFiles;
    },
    queueUploading() {
      return this.uploads.some(upload => upload.uploading);
    }
  },
  created() {
    if (!this.files.length) {
      return;
    }

    this.uploads = this.files.map(file => ({
      file: {
        ...file,
        upload: {
          uuid: file.id
        }
      },
      uploading: false,
      successful: true
    }));
  },
  beforeDestroy() {
    this.isDestroying = true;

    this.uploads.forEach(upload => upload.source && upload.source.cancel());
  },
  destroyed() {
    this.isDestroying = false;
  },
  methods: {
    updateUploading(index, options) {
      this.$set(this.uploads, index, {
        ...this.uploads[index],
        ...options
      });
    },
    handleUploadError(index, isReUpload) {
      this.updateUploading(index, {
        uploading: false,
        successful: false,
        error: this.$t('Web.FileUpload.UploadError')
      });

      if (!isReUpload) {
        const [failedFile] = this.uploads.splice(index, 1);

        this.uploads.unshift(failedFile);
      }
    },
    async uploadFile({ file, index, isReUpload }) {
      // skip upload if no callback (to upload manually)
      if (!this.uploadCallback) {
        return this.updateUploading(index, {
          uploading: false,
          successful: true
        });
      }

      try {
        const initialUploadsLength = this.uploads.length;

        if (initialUploadsLength === 1) {
          this.$emit('queue-upload-start');
        }

        this.$emit('upload-start');

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this.updateUploading(index, {
          uploading: true,
          successful: false,
          source
        });

        const response = await this.uploadCallback({
          value: file,
          reqConfig: {
            cancelToken: source.token
          }
        });

        const isCancelled = this.uploads.length < initialUploadsLength;

        if (isCancelled) {
          return;
        }

        if (!response) {
          return this.handleUploadError(index, isReUpload);
        }

        this.updateUploading(index, {
          uploading: false,
          successful: true
        });

        this.$emit('upload-success', { file, response });
      } catch (e) {
        console.error(e);

        this.handleUploadError(index, isReUpload);
      } finally {
        if (this.uploads[index]) {
          this.updateUploading(index, {
            source: null
          });
        }

        this.$emit('upload-end');

        if (!this.queueUploading) {
          this.$emit('queue-upload-end');
        }
      }
    },
    checkDuplicate({ name }) {
      return this.uploads.some(({ file }) => file.name === name);
    },
    async onFileAdded(file, insertIndex = null) {
      if (this.checkDuplicate(file)) {
        return this.removeFile(file);
      }

      const isReUpload = insertIndex !== null;

      if (this.validator) {
        const { valid, errors } = await this.validator([file]);

        // if the file is invalid, then insert to start of the array
        if (!valid) {
          const fileData = {
            file,
            uploading: false,
            successful: false,
            error: errors[0] || this.$t('Web.FileUpload.UploadError')
          };

          if (isReUpload) {
            this.uploads.splice(insertIndex, 0, fileData);
          } else {
            this.uploads.unshift(fileData);
          }

          if (this.isMaxFilesReached) {
            this.disableDropzone();
          }

          return;
        }
      }

      // if the file is valid, then upload\reupload
      const index = isReUpload ? insertIndex : this.uploads.length;

      this.uploads.splice(index, 1, {
        file,
        uploading: true
      });

      if (this.isMaxFilesReached) {
        this.disableDropzone();
      }

      if (this.uploads.length > this.maxFiles) {
        return this.removeFile(file);
      }

      this.emitFilesUpdated();
      this.emitFileAdded(file);
      this.uploadFile({ file, index, isReUpload });
    },
    onFileRemoved(file) {
      if (this.isDestroying) {
        return;
      }

      this.uploads = this.uploads.filter(({ file: localFile }) => {
        return file.upload.uuid !== localFile.upload.uuid;
      });

      this.$nextTick(() => {
        this.emitFilesUpdated();
        this.emitFileRemoved(file);

        if (this.manualDisabled && !this.isMaxFilesReached) {
          this.enableDropzone();
        }
      });
    },
    handleRemoveFile(file) {
      if (this.removeConfirm) {
        return this.$emit('remove-file', {
          file,
          remove: () => this.removeFile(file)
        });
      }

      this.removeFile(file);
    },
    removeFile(file) {
      const currentFile = this.uploads.find(({ file: localFile }) => {
        return file.name === localFile.name;
      });

      if (currentFile?.uploading && currentFile?.source) {
        currentFile.source.cancel();
      }

      this.$refs.slDropzone.removeFile(file);
    },
    handleReUploadFile(file, index) {
      this.onFileRemoved(file);
      this.onFileAdded(file, index);
    },
    disableDropzone() {
      this.$refs.slDropzone.disable();
      this.manualDisabled = true;
    },
    enableDropzone() {
      this.$refs.slDropzone.enable();
      this.manualDisabled = false;
    },
    emitFilesUpdated() {
      const files = this.uploads.map(upload => upload.file);

      this.$emit('files-updated', files);
      this.validator(files);
    },
    emitFileAdded(file) {
      this.$emit('file-added', file);
    },
    emitFileRemoved(file) {
      this.$emit('file-removed', file);
    },
    validate() {
      if (!this.uploads.length) {
        return false;
      }

      return this.uploads.every(upload => upload.successful);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-dropzone/index.scss';
</style>
