<template>
  <div class="sl-table-select-wrapper">
    <SlSelect
      v-if="isSelectVisible"
      ref="select"
      v-bind="$attrs"
      :max-height="maxHeight"
      :placeholder="' '"
      :disabled="disabled"
      class="sl-table-select-wrapper"
      :class="{
        [`color--${foreground}`]: foreground,
        [`sl-table-select--${type}`]: type,
        'sl-table-select-wrapper--editable-bg': editableBg,
        [`color--${foreground}`]: foreground
      }"
      :append-to-body="appendToBody"
      :append-to-body-option-selector="`sl-table-select-options--${type}`"
      @open="handleOpen"
      @close="handleClose"
      @input="(value) => $emit('input', value)"
      @clear="(value) => $emit('clear', value)"
    >
      <template
        v-for="(_, slot) in $scopedSlots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope || {}"
        />
      </template>
    </SlSelect>
    <div
      v-else
      :class="['sl-table-select__text', {
        [`color--${foreground}`]: foreground
      }]"
      tabindex="-1"
      @click.left.exact="handleSelectFocus"
    >
      {{ textValue }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlTableSelect',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'header',
      validator: (t) => ['header', 'row'].includes(t)
    },
    foreground: {
      type: String,
      default: ''
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    textValue: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: Number,
      default: 1000
    },
    disabled: Boolean,
    editableBg: Boolean,
    focusVisible: Boolean
  },
  data() {
    return {
      isFocused: false
    };
  },
  computed: {
    isSelectVisible() {
      return !this.focusVisible || this.isFocused;
    }
  },
  methods: {
    handleSelectFocus() {
      if (this.disabled) {
        return;
      }

      this.isFocused = true;

      this.$nextTick(this.toggle);
    },
    handleOpen() {
      this.$emit('open');
    },
    handleClose() {
      this.isFocused = false;

      this.$emit('close');
    },
    toggle() {
      this.$refs.select.toggle();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-table/inputs/sl-table-select.scss";
</style>
