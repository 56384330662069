import axios from '@/plugins/axios';

export default {
  exportNodeToXlsx(params) {
    return axios.get('demand/node/xlsx', { params });
  },
  clearNodeOverrides(params) {
    return axios.post('demand/node/clear_overrides', null, { params });
  },
  clearNodeSettings(params) {
    return axios.post('demand/node/clear_settings', null, { params });
  },
  getNodeNotice(params) {
    return axios.get('demand/node/notice', { params });
  },
  updateNodeNotice(params, body) {
    return axios.post('demand/node/notice', body, { params });
  }
};
