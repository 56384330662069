import { routeNames } from '@/config/router/router.config';

export const accessStateKeys = {
  EDITABLE: 'Editable',
  READ_ONLY: 'Read-only',
  NONE: 'None'
};

export const noPlanningItemsSensitiveRoutes = [
  routeNames.DEMAND,
  routeNames.INVENTORY,
  routeNames.INTERSITE,
  routeNames.REPORTS,
  routeNames.BATCHES_ON_HAND,
  routeNames.PROMOTIONS,
  routeNames.SUPPLIERS,
  routeNames.BILL_OF_MATERIALS,
  routeNames.INVENTORY_IN_TRANSITION,
  routeNames.INTERSITE_IN_TRANSITION,
  routeNames.INVENTORY_PLANNED_ORDERS,
  routeNames.INTERSITE_PLANNED_ORDERS,
  routeNames.CONSTRAINED_PLAN
];

export const defaultFeatures = () => ({
  inventoryFeature: false,
  distribution: false,
  buildInvModel: false,
  batchesOnHand: false,
  bom: false,
  abc: false,
  location: false,
  channel: false,
  intersite: false,
  uom: false,
  exportForecast: false,
  exportInventory: false,
  exportOrders: false
});

export const defaultPermissions = () => ({
  accessPurchasingData: false,
  accessSalesData: false,
  approveForecast: false,
  editOrderCycleUnit: false,
  exportForecast: false,
  exportInventory: false,
  exportOrders: false,
  manageAbc: false,
  manageAutoExport: false,
  manageAutoUpdate: false,
  manageBatchesOnHand: false,
  manageBom: false,
  manageChanges: { edit: false, view: false, none: true },
  manageCollections: false,
  manageConstrainedPlan: { edit: false, view: false, none: true },
  manageCustomRows: false,
  manageDashboards: { edit: false, view: false, none: true },
  manageDemand: { edit: false, view: false, none: true },
  manageFilters: false,
  manageForecastReport: { edit: false, view: false, none: true },
  manageInventory: { edit: false, view: false, none: true },
  manageInventoryInTransition: { edit: false, view: false, none: true },
  manageInventoryPlannedOrders: { edit: false, view: false, none: true },
  toggleOptimization: false,
  manageIntersite: { edit: false, view: false, none: true },
  manageIntersiteInTransition: { edit: false, view: false, none: true },
  manageIntersitePlannedOrders: { edit: false, view: false, none: true },
  manageProjectConnection: false,
  manageProjectSettings: false,
  managePromotions: false,
  manageSeasonalityPatterns: false,
  manageSubstitutions: false,
  manageSuppliers: false,
  manageTransferRegions: { edit: false, view: false, none: true },
  manageUnitsOfMeasure: false,
  manageUsers: false,
  updateProject: false,
  managePlanningItems: false
});

export const permissionByRouteName = {
  [routeNames.DEMAND]: 'manageDemand',
  [routeNames.INVENTORY]: 'manageInventory',
  [routeNames.INVENTORY_IN_TRANSITION]: 'manageInventoryInTransition',
  [routeNames.INVENTORY_PLANNED_ORDERS]: 'manageInventoryPlannedOrders',
  [routeNames.INTERSITE]: 'manageIntersite',
  [routeNames.INTERSITE_IN_TRANSITION]: 'manageIntersiteInTransition',
  [routeNames.INTERSITE_PLANNED_ORDERS]: 'manageIntersitePlannedOrders',
  [routeNames.REPORTS]: 'manageForecastReport',
  [routeNames.DASHBOARD]: 'manageDashboards',
  [routeNames.CHANGES]: 'manageChanges',
  [routeNames.TRANSFER_REGIONS]: 'manageTransferRegions',
  [routeNames.PROMOTIONS]: 'managePromotions',
  [routeNames.CUSTOM_ROWS]: 'manageCustomRows',
  [routeNames.SEASONALITY_PATTERNS]: 'manageSeasonalityPatterns',
  [routeNames.COLLECTIONS]: 'manageCollections',
  [routeNames.SUPPLIERS]: 'manageSuppliers',
  [routeNames.SETTINGS]: 'manageProjectSettings',
  [routeNames.BILL_OF_MATERIALS]: 'manageBom',
  [routeNames.REMOVAL_SUBSTITUTION_RULES]: 'manageSubstitutions',
  [routeNames.BATCHES_ON_HAND]: 'manageBatchesOnHand',
  [routeNames.EDIT_CONNECTION]: 'manageProjectConnection',
  [routeNames.EDIT_CONNECTION_CONNECTOR]: 'manageProjectConnection',
  [routeNames.USERS]: 'manageUsers',
  [routeNames.ROLES]: 'manageUsers',
  [routeNames.CREATE_ROLE]: 'manageUsers',
  [routeNames.EDIT_ROLE]: 'manageUsers',
  [routeNames.CATALOGS]: 'manageUsers',
  [routeNames.CREATE_CATALOG]: 'manageUsers',
  [routeNames.EDIT_CATALOG]: 'manageUsers'
};
