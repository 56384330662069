import { integrationExportTypes, integrationTypes } from '.';

export const tabKeys = {
  // settings
  SETTINGS: 'settings',
  // import
  TRANSACTIONS: 'transactions',
  ITEM_INFO: 'inventory',
  CHANNEL_INFO: 'channels',
  ORDERS_RECEIVE: 'arrivals',
  ORDERS_SHIP: 'backorders',
  SUBSTITUTIONS: 'substitutions',
  CHANNEL_SUBSTITUTIONS: 'channelSubstitutions',
  BOM: 'bom',
  PROMOTIONS: 'promotions',
  BATCH_EXPIRATIONS: 'expirations',
  INVENTORY_PARAMETERS: 'inventoryParameters',
  SUPPLIERS: 'suppliers',
  TRANSFER_REGIONS: 'transferRegions',
  // export
  PLANNED_ORDERS: 'planned_orders',
  MIN_MAX: 'minmax',
  INVENTORY_REPORT: 'inventory_report',
  INVENTORY_FORECAST: 'forecast_report',
  REPORTS: 'reports'
};

export const importTabKeys = [
  tabKeys.TRANSACTIONS,
  tabKeys.ITEM_INFO,
  tabKeys.CHANNEL_INFO,
  tabKeys.ORDERS_RECEIVE,
  tabKeys.ORDERS_SHIP,
  tabKeys.SUBSTITUTIONS,
  tabKeys.CHANNEL_SUBSTITUTIONS,
  tabKeys.BOM,
  tabKeys.PROMOTIONS,
  tabKeys.BATCH_EXPIRATIONS,
  tabKeys.INVENTORY_PARAMETERS,
  tabKeys.SUPPLIERS,
  tabKeys.TRANSFER_REGIONS
];

export const exportTabKeys = [
  tabKeys.PLANNED_ORDERS,
  tabKeys.MIN_MAX,
  tabKeys.INVENTORY_REPORT,
  tabKeys.INVENTORY_FORECAST,
  tabKeys.REPORTS
];

export const requiredTabs = [tabKeys.TRANSACTIONS, tabKeys.ITEM_INFO];

export const defaultSettingTabs = (ctx) => ([
  {
    label: ctx.$t('Web.Integrations.TitleSettings'),
    value: tabKeys.SETTINGS,
    component: 'Settings'
  }
]);

export const importSettingTabsByType = {
  [integrationTypes.NETSUITE]: defaultSettingTabs,
  [integrationTypes.DYNAMICS_NAV]: (ctx) => {
    return defaultSettingTabs(ctx).map(tab => ({ ...tab, required: true }));
  }
};

export const importSettingTabs = (ctx, { type }) => importSettingTabsByType[type] ? importSettingTabsByType[type](ctx) : [];

export const exportSettingTabsByType = {
  [integrationTypes.DATABASE]: defaultSettingTabs
};

export const exportSettingTabs = (ctx, { type }) => exportSettingTabsByType[type] ? exportSettingTabsByType[type](ctx) : [];

export const defaultImportTabs = (ctx) => ([
  {
    label: ctx.$t('DbImportDialog.Ui.tabMainQuery:at'),
    value: tabKeys.TRANSACTIONS,
    component: 'Transactions'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabItemQry:at'),
    value: tabKeys.ITEM_INFO,
    component: 'BaseTab'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabChannelsInfo:at'),
    value: tabKeys.CHANNEL_INFO,
    component: 'BaseTab'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabArrivals:at'),
    value: tabKeys.ORDERS_RECEIVE,
    component: 'OrdersToReceive'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabBackorders:at'),
    value: tabKeys.ORDERS_SHIP,
    component: 'BaseTab'
  },
  {
    label: ctx.$t('Web.CustomApi.Input.Substitutions'),
    value: tabKeys.SUBSTITUTIONS,
    component: 'BaseTab'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabBomQuery:at'),
    value: tabKeys.BOM,
    component: 'BaseTab'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabPromotions:at'),
    value: tabKeys.PROMOTIONS,
    component: 'BaseTab'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabBatches:at'),
    value: tabKeys.BATCH_EXPIRATIONS,
    component: 'BaseTab'
  },
  {
    label: ctx.$t('Web.DbImport.TabInventoryParameters'),
    value: tabKeys.INVENTORY_PARAMETERS,
    component: 'BaseTab'
  },
  {
    label: ctx.$t('Web.Suppliers.PageTitle'),
    value: tabKeys.SUPPLIERS,
    component: 'BaseTab'
  }
]);

const transformRegionsTab = (ctx) => ({
  label: ctx.$t('Web.TransferRegions.PageTitle'),
  value: tabKeys.TRANSFER_REGIONS,
  component: 'BaseTab'
});

const channelSubstitutionsTab = (ctx) => ({
  label: ctx.$t('Web.CustomApi.Input.ChannelSubstitutions'),
  value: tabKeys.CHANNEL_SUBSTITUTIONS,
  component: 'BaseTab'
});

const amazonTabs = [
  tabKeys.TRANSACTIONS,
  tabKeys.ITEM_INFO,
  tabKeys.ORDERS_RECEIVE,
  tabKeys.SUPPLIERS
];

const customApiTabs = [
  tabKeys.TRANSACTIONS,
  tabKeys.ITEM_INFO,
  tabKeys.ORDERS_SHIP,
  tabKeys.ORDERS_RECEIVE,
  tabKeys.BOM,
  tabKeys.SUPPLIERS,
  tabKeys.SUBSTITUTIONS,
  tabKeys.CHANNEL_SUBSTITUTIONS,
  tabKeys.CHANNEL_INFO,
  tabKeys.BATCH_EXPIRATIONS
];

const netsuiteTabs = [
  tabKeys.TRANSACTIONS,
  tabKeys.ITEM_INFO,
  tabKeys.ORDERS_SHIP,
  tabKeys.ORDERS_RECEIVE,
  tabKeys.BOM,
  tabKeys.SUPPLIERS
];

const importTabsByType = {
  [integrationTypes.AGGREGATION]: (ctx) => ([
    ...defaultImportTabs(ctx),
    transformRegionsTab(ctx),
    channelSubstitutionsTab(ctx)
  ]),
  [integrationTypes.AMAZON]: (ctx) => {
    return defaultImportTabs(ctx).filter(tab => amazonTabs.includes(tab.value));
  },
  [integrationTypes.QB_DESKTOP]: defaultImportTabs,
  [integrationTypes.QB_ONLINE]: defaultImportTabs,
  [integrationTypes.CUSTOM_API]:  (ctx) => {
    return [
      ...defaultImportTabs(ctx),
      channelSubstitutionsTab(ctx)
    ]
      .filter(tab => customApiTabs.includes(tab.value));
  },
  [integrationTypes.BC365]: defaultImportTabs,
  [integrationTypes.DATABASE]: (ctx) => ([
    ...defaultImportTabs(ctx),
    transformRegionsTab(ctx)
  ]),
  [integrationTypes.SAP]: defaultImportTabs,
  [integrationTypes.CIN7]: defaultImportTabs,
  [integrationTypes.CIN7CORE]: defaultImportTabs,
  [integrationTypes.NETSUITE]: (ctx) => {
    return defaultImportTabs(ctx).filter(tab => netsuiteTabs.includes(tab.value));
  },
  [integrationTypes.DYNAMICS_NAV]: defaultImportTabs,
  [integrationTypes.DYNAMICS_GP]: defaultImportTabs,
  [integrationTypes.FISHBOWL]: defaultImportTabs,
  [integrationTypes.SPIRE]: defaultImportTabs,
  [integrationTypes.SHOPIFY]: defaultImportTabs
};

export const importTabs = (ctx, { type }) => importTabsByType[type]?.(ctx) || [];

const exportTabsByType = {
  [integrationExportTypes.PLANNED_ORDERS]: (ctx) => ([
    {
      label: ctx.$t('Web.DbImport.TabPlannedOrders'),
      value: tabKeys.PLANNED_ORDERS,
      component: 'PlannedOrders',
      required: true
    },
    {
      label: ctx.$t('Web.DbImport.TabMinMax'),
      value: tabKeys.MIN_MAX,
      component: 'MinMax'
    }
  ]),
  [integrationExportTypes.REPORTS]: (ctx) => ([
    {
      label: ctx.$t('Web.DbImport.TabInvReport'),
      value: tabKeys.INVENTORY_REPORT,
      component: 'InventoryReport'
    },
    {
      label: ctx.$t('Web.DbImport.TabInvForecast'),
      value: tabKeys.INVENTORY_FORECAST,
      component: 'InventoryForecast'
    },
    {
      label: ctx.$t('Web.DbImport.TabReports'),
      value: tabKeys.REPORTS,
      component: 'Reports'
    }
  ])
};

export const exportTabs = (ctx, { exportType }) => exportTabsByType[exportType](ctx);

export const exportInitialValuesByTab = {
  [tabKeys.PLANNED_ORDERS]: {
    orderQty: 1,
    reorderPoint: 0
  },
  [tabKeys.MIN_MAX]: {
    reorderPoint: 1,
    maxInventory: 0
  },
  [tabKeys.INVENTORY_REPORT]: {
    onHand: 0,
    supplyDays: 0,
    receiveQty: 0
  },
  [tabKeys.INVENTORY_FORECAST]: {
    demandForecast: 0,
    orderingPlan: 0,
    inventoryProjection: 0
  },
  [tabKeys.REPORTS]: {
    demandForecast: 0,
    projectedRevenue: 0
  }
};

export const multipleSlots = [
  'HOLE_ITEM_CAT',
  'HOLE_LOCATION_CAT',
  'HOLE_CHANNEL_CAT',
  'HOLE_AUXFIELD'
];
