import { mapActions, mapGetters, mapState } from 'vuex';
import { fgsActionTypes } from '@/config/shared/contextMenu.config';
import { contextMenuFgs } from '@/config/shared/fgs.config';
import modalsId from '@/config/shared/modalsId.config';
import { copyToClipboard } from '@/helpers/utils/clipboard';
import { routeNames } from '@/config/router/router.config';
import { bomTypes } from '@/config/item/bom.config';
import { projectRedirect } from '@/helpers/router';

export const treeNodeActions = {
  provide() {
    return {
      copyItem: this.copyItem,
      toggleApproveState: this.toggleApproveState,
      toggleCheckedState: this.toggleCheckedState,
      toggleNeedsAttentionState: this.toggleNeedsAttentionState,
      openBOMs: this.openBOMs,
      removeFromImport: this.removeFromImport,
      substituteItem: this.substituteItem
    };
  },
  computed: {
    ...mapState({
      activeNode: state => state.demand.tree.active_node
    }),
    ...mapGetters({
      getActionFgs: 'demand/tree/getActionFgs'
    })
  },
  methods: {
    ...mapActions({
      getItemClones: 'substitutions/getItemClones',
      toggleApproveState: 'demand/tree/toggleApproveState',
      toggleCheckedState: 'demand/tree/toggleCheckedState',
      toggleNeedsAttentionState: 'demand/tree/toggleNeedsAttentionState',
      setBomEntry: 'bom/setSelectedEntry'
    }),
    isBomDisabled(context) {
      return !this.getActionFgs(contextMenuFgs.ITEM_BOM, context[fgsActionTypes.ENABLED]);
    },
    isSubstitutionsDisabled(action, context) {
      return !this.getActionFgs(action, context[fgsActionTypes.ENABLED]) || !this.can.manageSubstitutions;
    },
    copyItem({ uniId }) {
      copyToClipboard(uniId.item);
    },
    async openBOMs(context) {
      if (this.isBomDisabled(context)) {
        return;
      }

      await this.setBomEntry({
        ...context.uniId,
        route: this.$route.name
      });

      projectRedirect({
        name: routeNames.BILL_OF_MATERIALS,
        params: {
          type: bomTypes.ITEM
        }
      });
    },
    async removeFromImport({ uniId, ...context }) {
      if (this.isSubstitutionsDisabled(contextMenuFgs.REMOVE_FROM_IMPORT, context)) {
        return;
      }

      await this.getItemClones(uniId.item);

      this.showModal(modalsId.REMOVE_FROM_IMPORT, {
        activeItemCode: uniId.item
      });
    },
    async substituteItem({ uniId, ...context }) {
      if (this.isSubstitutionsDisabled(contextMenuFgs.SUBSTITUTE, context)) {
        return;
      }

      await this.getItemClones(uniId.item);

      this.showModal(modalsId.SUBSTITUTE_AN_ITEM, {
        activeItemCode: uniId.item
      });
    }
  }
};