<template>
  <div class="sl-swiper__slide">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SlSwiperSlide'
};
</script>