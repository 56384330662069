import { exportFgs } from './fgs.config';
import { routeNames } from '@/config/router/router.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export const settingsTabKeys = {
  GENERAL: 'general',
  PROJECT: 'project',
  FORECAST: 'forecast',
  ABC: 'abc',
  AUTOMATION: 'automation',
  INVENTORY: 'inventory',
  DC: 'dc'
};

export const settingsTabItems = (ctx) =>  ([
  {
    label: ctx.$t('Settings.TabNames.project'),
    component: 'ProjectTab',
    value: settingsTabKeys.PROJECT
  },
  {
    label: ctx.$t('Settings.TabNames.forecast'),
    component: 'ForecastTab',
    value: settingsTabKeys.FORECAST
  },
  {
    label: ctx.$t('Web.Settings.TabNames.Abc'),
    component: 'AbcAnalysisTab',
    value: settingsTabKeys.ABC
  },
  {
    label: ctx.$t('Settings.TabNames.inventory'),
    component: 'InventoryTab',
    value: settingsTabKeys.INVENTORY
  },
  {
    label: ctx.$t('Settings.TabNames.dc'),
    component: 'DCTab',
    value: settingsTabKeys.DC
  },
  {
    label: ctx.$t('Settings.TabNames.automation'),
    component: 'AutomationTab',
    value: settingsTabKeys.AUTOMATION
  }
]);

export const abcLevelKeys = {
  ITEM: 'Item',
  ITEM_LOCATION: 'ItemLoc',
  ITEM_LOCATION_CHANNEL: 'ItemLocChannel'
};

export const abcScopeKeys = {
  ALL_ITEMS: 'AllItems',
  ITEM_CATEGORY: 'ItemCat',
  LOCATION: 'Location',
  LOCATION_CATEGORY: 'LocCat'
};

export const abcAnalysisSumKeys = {
  NONE: 'None',
  UNITS: 'Units',
  REVENUE: 'Revenue',
  PROFIT: 'Profit',
  COST_SOLD: 'CostSold',
  INVENTORY_COST: 'InventoryCost',
  FORECAST: 'Forecast',
  PROJECTED_REVENUE: 'ProjectedRevenue'
};

export const abcAnalysisRangeKeys = {
  UNCERTAINTY: 'Uncertainty',
  VARIABILITY: 'Variability'
};

export const abcAnalysisSumFuncOptions = (ctx) => ([
  {
    text: ctx.$t('WiAbc.Func.None'),
    value: abcAnalysisSumKeys.NONE
  },
  {
    text: ctx.$t('WiAbc.Func.Units'),
    value: abcAnalysisSumKeys.UNITS
  },
  {
    text: ctx.$t('WiAbc.Func.Revenue'),
    value: abcAnalysisSumKeys.REVENUE
  },
  {
    text: ctx.$t('WiAbc.Func.Profit'),
    value: abcAnalysisSumKeys.PROFIT
  },
  {
    text: ctx.$t('WiAbc.Func.CostSold'),
    value: abcAnalysisSumKeys.COST_SOLD
  },
  {
    text: ctx.$t('WiAbc.Func.InvCost'),
    value: abcAnalysisSumKeys.INVENTORY_COST
  },
  {
    text: ctx.$t('Main.Cols.AbcFunc.Forecast'),
    value: abcAnalysisSumKeys.FORECAST
  },
  {
    text: ctx.$t('Main.Cols.AbcFunc.ProjectedRevenue'),
    value: abcAnalysisSumKeys.PROJECTED_REVENUE
  },
  {
    text: ctx.$t('Settings.Abc.uncertainty'),
    value: abcAnalysisRangeKeys.UNCERTAINTY
  },
  {
    text: ctx.$t('Settings.Abc.variability'),
    value: abcAnalysisRangeKeys.VARIABILITY
  }
]);

export const abcTypeKeys = {
  AB: 'AB',
  ABC: 'ABC',
  ABCD: 'ABCD'
};

export const abcTypesOptions = [
  {
    label: 2,
    value: abcTypeKeys.AB
  },
  {
    label: 3,
    value: abcTypeKeys.ABC
  },
  {
    label: 4,
    value: abcTypeKeys.ABCD
  }
];

export const classItemsTypes = {
  INPUT: 'input',
  TEXT: 'text'
};

export const abcClassItems = [
  {
    label: 'A',
    type: classItemsTypes.INPUT,
    key: 'abcAModel'
  },
  {
    label: 'B',
    type: classItemsTypes.INPUT,
    key: 'abcBModel'
  },
  {
    label: 'C',
    type: classItemsTypes.INPUT,
    key: 'abcCModel'
  },
  {
    label: 'D',
    type: classItemsTypes.TEXT,
    key: 'abcDModel'
  }
];

export const abcClassRangeItems = [
  {
    label: 'A',
    type: classItemsTypes.INPUT,
    key: 'abcXModel'
  },
  {
    label: 'B',
    type: classItemsTypes.INPUT,
    key: 'abcYModel'
  },
  {
    label: 'C',
    type: classItemsTypes.TEXT,
    key: 'abcZModel'
  }
];

export const xyzClassItems = [
  {
    label: 'X',
    type: classItemsTypes.INPUT,
    key: 'xyzAModel'
  },
  {
    label: 'Y',
    type: classItemsTypes.INPUT,
    key: 'xyzBModel'
  },
  {
    label: 'Z',
    type: classItemsTypes.INPUT,
    key: 'xyzCModel'
  },
  {
    label: 'F',
    type: classItemsTypes.TEXT,
    key: 'xyzFModel'
  }
];

export const xyzClassRangeItems = [
  {
    label: 'X',
    type: classItemsTypes.INPUT,
    key: 'xyzXModel'
  },
  {
    label: 'Y',
    type: classItemsTypes.INPUT,
    key: 'xyzYModel'
  },
  {
    label: 'Z',
    type: classItemsTypes.TEXT,
    key: 'xyzZModel'
  }
];

export const sumErrorMessage = (ctx) => ctx.$t('Settings.Abc.sumError');

export const autoUpdateTypes = {
  DAILY: 'daily',
  BY_HOURS: 'byHours'
};

export const exportCheckboxes = (ctx) => ([
  {
    label: ctx.$t('Settings.Ui.chkExportInvOrders'),
    valueFgs: exportFgs.INVENTORY_PLANNED_ORDERS,
    permissionKey: 'exportOrders'
  },
  {
    label: ctx.$t('Settings.Ui.chkExportInvReport'),
    valueFgs: exportFgs.INVENTORY_REPORT,
    permissionKey: 'exportInventory'
  },
  {
    label: ctx.$t('Settings.Ui.chkExportForecastReport'),
    valueFgs: exportFgs.FORECAST_REPORT,
    permissionKey: 'exportForecast'
  }
]);

export const orderCycleValues = {
  PERIOD: 'Period',
  DAY: 'Day',
  LEADTIME: 'Leadtime'
};

export const orderCycleUnitLabelByValue = (period, ctx) => {
  return {
    [orderCycleValues.PERIOD]: ctx.$t(`Common.AfterNum.${period}`),
    [orderCycleValues.DAY]: ctx.$t('Common.AfterNum.Day'),
    [orderCycleValues.LEADTIME]: ctx.$t('Settings.LeadTime')
  };
};

export const orderStrategyKeys = {
  PERIOD: 'Period',
  MINMAX: 'MinMax'
};

export const inventorySimulationKeys = {
  TODAY: 'Today',
  LAST: 'LastTransaction'
};

export const settingsTabByRoute = {
  [routeNames.INVENTORY]: settingsTabKeys.INVENTORY,
  [routeNames.INTERSITE]: settingsTabKeys.INVENTORY
};

export const supplyUnitPeriods = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month'
};

export const accuracyMeasure = {
  WMAPE: 'wmape',
  ACCURACY: 'accuracy'
};

export const safetyShelfTypeItems = (ctx) => ([
  {
    label: ctx.$t('Settings.Ui.radioMaxSafetyShelf'),
    value: 'Max'
  },
  {
    label: ctx.$t('Settings.Ui.radioSumSafetyShelf'),
    value: 'Sum'
  }
]);

export const inventorySimulationOptions = (ctx, time) => ([
  {
    label: ctx.$t('Settings.Ui.grpInvSimulationToday'),
    info: getTooltip({
      content: ctx.$t('Settings.Ui.lbTimezoneTooltip', { 1: time }),
      placement: 'bottom'
    }),
    value: inventorySimulationKeys.TODAY
  },
  {
    label: ctx.$t('Settings.Ui.grpInvSimulationLast'),
    value: inventorySimulationKeys.LAST
  }
]);

export const sourceLocationPriorityOptions = (ctx) => ([
  {
    label: ctx.$t('Web.TransferRegions.AvailableForTransfer'),
    value: true
  },
  {
    label: ctx.$t('Web.TransferRegions.LeadTime'),
    value: false
  }
]);

export const stockoutDaysAggregationItems = (ctx) => ([
  {
    label: ctx.$t('Settings.Ui.radioStockoutsAvg'),
    value: 'Avg'
  },
  {
    label: ctx.$t('Settings.Ui.radioStockoutsSum'),
    value: 'Sum'
  }
]);

export const orderStrategyOptions = (ctx) => ([
  {
    label: ctx.$t('WiInventorySettings.Ui.radioJit'),
    value: orderStrategyKeys.PERIOD
  },
  {
    label: ctx.$t('WiInventorySettings.Ui.radioMinMax'),
    value: orderStrategyKeys.MINMAX
  }
]);

export const orderDurationValues = {
  ORDER_CYCLE: null,
  CUSTOM: 30
};

export const orderDurationOptions = (ctx, value) => ([
  {
    label: ctx.$t('Web.Settings.OrderDuration.OrderCycle'),
    value: orderDurationValues.ORDER_CYCLE
  },
  {
    label: ctx.$t('Web.Settings.OrderDuration.Custom'),
    value: value !== null ? value : orderDurationValues.CUSTOM
  }
]);

export const salesOrderForecastBasedOnOptions = (ctx) => ([
  {
    label: ctx.$t('Web.Settings.SalesOrderForecast.Entry'),
    value: false
  },
  {
    label: ctx.$t('Web.Settings.SalesOrderForecast.Channel'),
    value: true
  }
]);

export const revenueForecastOptions = (ctx) => ([
  {
    label: ctx.$t('Web.Settings.CalculateRevenue.FinalForecast'),
    value: false
  },
  {
    label: ctx.$t('Web.Settings.CalculateRevenue.SalesOrderForecast'),
    value: true
  }
]);

export const supplyUnitItems = (ctx) => ([
  {
    label: ctx.$t('Web.Settings.radioSupplyDays'),
    value: supplyUnitPeriods.DAY
  },
  {
    label: ctx.$t('Web.Settings.radioSupplyWeeks'),
    value: supplyUnitPeriods.WEEK
  },
  {
    label: ctx.$t('Web.Settings.radioSupplyMonths'),
    value: supplyUnitPeriods.MONTH
  }
]);

export const accuracyMeasureItems = (ctx) => ([
  {
    label: ctx.$t('Settings.Ui.radioAccuracyWmape'),
    value: accuracyMeasure.WMAPE
  },
  {
    label: ctx.$t('Settings.Project.radioAccuracyAcc'),
    value: accuracyMeasure.ACCURACY,
    info: {
      theme: 'dark',
      content: ctx.$t('Settings.Project.radioAccuracyAccTooltip'),
      placement: 'right'
    }
  }
]);

export const orderCycleUnitItems = (ctx) => ([
  {
    label: ctx.$t('Settings.Ui.radioOCUnitPeriods'),
    value: orderCycleValues.PERIOD
  },
  {
    label: ctx.$t('Settings.Ui.radioOCUnitDays'),
    value: orderCycleValues.DAY
  },
  {
    label: ctx.$t('Settings.Ui.radioOCUnitLeadTimes'),
    value: orderCycleValues.LEADTIME
  }
]);

export const timeZones = [
  {
    'label': 'UTC -11:00',
    'value': -660
  },
  {
    'label': 'UTC -10:00',
    'value': -600
  },
  {
    'label': 'UTC -09:00',
    'value': -540
  },
  {
    'label': 'UTC -08:00',
    'value': -480
  },
  {
    'label': 'UTC -07:00',
    'value': -420
  },
  {
    'label': 'UTC -06:00',
    'value': -360
  },
  {
    'label': 'UTC -05:00',
    'value': -300
  },
  {
    'label': 'UTC -04:00',
    'value': -240
  },
  {
    'label': 'UTC -03:00',
    'value': -180
  },
  {
    'label': 'UTC -02:00',
    'value': -120
  },
  {
    'label': 'UTC -01:00',
    'value': -60
  },
  {
    'label': 'UTC +00:00',
    'value': 0
  },
  {
    'label': 'UTC +01:00',
    'value': 60
  },
  {
    'label': 'UTC +02:00',
    'value': 120
  },
  {
    'label': 'UTC +03:00',
    'value': 180
  },
  {
    'label': 'UTC +04:00',
    'value': 240
  },
  {
    'label': 'UTC +05:00',
    'value': 300
  },
  {
    'label': 'UTC +06:00',
    'value': 360
  },
  {
    'label': 'UTC +07:00',
    'value': 420
  },
  {
    'label': 'UTC +08:00',
    'value': 480
  },
  {
    'label': 'UTC +09:00',
    'value': 540
  },
  {
    'label': 'UTC +10:00',
    'value': 600
  },
  {
    'label': 'UTC +11:00',
    'value': 660
  },
  {
    'label': 'UTC +12:00',
    'value': 720
  },
  {
    'label': 'UTC +13:00',
    'value': 780
  },
  {
    'label': 'UTC +14:00',
    'value': 840
  }
];
