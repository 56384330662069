import Vue from 'vue';
import store from '@/store';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import amplitudeConfig from '@/config/plugins/amplitude.config';
import { getRouteName } from '@/helpers/shared/router';
import { getVersion } from '@/helpers/shared/version';
import { ampli } from './ampli';
import { parse } from '@/helpers/utils/JSON';

const userPropsToDelete = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'initial_dclid',
  'initial_fbclid',
  'initial_gbraid',
  'initial_gclid',
  'initial_msclkid',
  'initial_referrer',
  'initial_referring_domain',
  'initial_rtd_cid',
  'initial_ttclid',
  'initial_twclid',
  'initial_utm_campaign',
  'initial_utm_content',
  'initial_utm_id',
  'initial_utm_medium',
  'initial_utm_source',
  'initial_utm_term',
  'initial_wbraid',
  'initial_ko_click_id',
  'initial_li_fat_id',
  'Account plan',
  'Account Plan',
  'tenant_id'
];

const groupPropsToDelete = [
  'Account plan',
  'name'
];

function getAuthState() {
  return Vue.mapAuthState()?.authState;
}

const enrichPageUrlPlugin = () => {
  return {
    execute: async(event) => {
      if (event.event_type === '[Amplitude] Page Viewed') {
        event.event_properties = {
          ...event.event_properties,
          'Streamline Page Name': getRouteName()
        };
      }

      return event;
    }
  };
};

export function initAmplitude(tenantMetadata) {
  const authState = getAuthState();
  const { email, tenantId } = authState.user;

  const API_KEY = amplitudeConfig.API_KEY_BY_ENV[process.env.VUE_APP_MODE];

  amplitude.init(API_KEY, email, {
    serverUrl: amplitudeConfig.SERVER_URL,
    autocapture: {
      elementInteractions: true
    },
    defaultTracking: {
      fileDownloads: false
    }
  });
  amplitude.add(sessionReplayPlugin());
  amplitude.add(enrichPageUrlPlugin());

  ampli.load({ client: { instance: amplitude } });

  const { tenantsState } = authState;

  const tenantName = tenantsState.tenants.find(t => t.tenantId === tenantId).name;
  const { accountPlan } = store.state.account.license_details;

  identifyGroup('Tenant', tenantId, {
    tenant_name: tenantName,
    'Account Plan': accountPlan,
    version: getVersion(),
    'Hubspot Pipeline ID': tenantMetadata.pipeline,
    'Server Max Users': store.getters['initialization/maxUsersCount']
  });

  const identify = new amplitude.Identify();

  userPropsToDelete.forEach((property) => {
    identify.unset(property);
  });

  amplitude.identify(identify);

  ampli.identify(null, {
    'Frontegg User Id': authState.user.id,
    streamlineLanguage: store.state.settings.language
  });

  window.amplitude = amplitude;
}

async function performAmplitudeRequest(f, args) {
  let authState = getAuthState();

  if (!authState) {
    return () => {};
  }

  let { user, tenantsState } = authState;

  while (!user || !tenantsState?.tenants?.length) {
    await new Promise(res => setTimeout(() => {
      authState = getAuthState();

      user = authState.user;
      tenantsState = authState.tenantsState;

      res();
    }, 100));
  }

  const tenantId = user.tenantId;
  const userTenant = tenantsState.tenants.find(t => t.tenantId === tenantId);
  const tenantMetadata = parse(userTenant.metadata);

  if (localStorage.getItem(amplitudeConfig.LS_FLAG)) {
    return f(...args, tenantMetadata);
  }

  const isCustomer = tenantMetadata.pipeline === amplitudeConfig.CUSTOMER_PIPELINE;
  const isFreeUser = store.getters['account/isFreePlan'];

  if (!(isCustomer || isFreeUser)) {
    return () => {};
  }

  return f(...args, tenantMetadata);
}

const identifyGroup = (type, name, props = {}) => {
  ampli.client.setGroup(type, name);

  const groupIdentifyEvent = new ampli.client.Identify();

  Object.entries(props).forEach(([key, value]) => {
    groupIdentifyEvent.set(key, value);
  });

  groupPropsToDelete.forEach((property) => {
    groupIdentifyEvent.unset(property);
  });

  ampli.client.groupIdentify(type, name, groupIdentifyEvent);
};

export const $amp_initAmplitude = (...args) => performAmplitudeRequest(initAmplitude, args);
