import Vue from 'vue';
import router from '@/router';
import sharedReportApi from '@/api/reports/shared';
import sortingApi from '@/api/sorting';
import ReportTableAdapter from '@/adapters/response/ReportTable.adapter';
import { ordersTypesKeyMap, tableTypeKeyMap } from '@/config/report/inventoryReport';
import { namespaceByRoute } from '@/config/report';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { uiSettingsKeys, uiTabByRoute } from '@/config/users/uiSettings.config';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { filterTypesByRouteName } from '@/config/filters';
import { PER_PAGE_MAX } from '@/config/shared/slTable.config';
import { getRouteName } from '@/helpers/shared/router';
import { getResizedIndexesFromClasses } from '@/helpers/report/shared';
import { getTableConfigParams, getTableConfigStructure } from '@/helpers/shared/tableConfig';
import { projectRedirect } from '@/helpers/router';

const types = {
  SET_TABLE: 'SET_TABLE',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG',
  SET_COLUMNS_VISIBILITY: 'SET_COLUMNS_VISIBILITY',
  SET_COLUMN_SIZES: 'SET_COLUMN_SIZES',
  SET_SCROLL_TOP: 'SET_SCROLL_TOP',
  SET_IS_REFETCHED: 'SET_IS_REFETCHED',
  SET_IS_UPDATING: 'SET_IS_UPDATING'
};

export const sharedReportModule = {
  namespaced: true,
  state() {
    return {
      table: null,
      tableConfig: getTableConfigStructure({
        perPage: PER_PAGE_MAX,
        queryKeys: ['search', 'perPage', 'page']
      }),
      columns_visibility: [],
      column_sizes: [],
      scroll_top: 0,
      isRefetched: false,
      is_updating: false
    };
  },
  mutations: {
    [types.SET_TABLE](state, value) {
      state.table = value;
    },
    [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
      Vue.set(state.tableConfig, key, value);
    },
    [types.SET_TABLE_CONFIG](state, payload) {
      Object.assign(state.tableConfig, payload);
    },
    [types.SET_COLUMNS_VISIBILITY](state, value) {
      state.columns_visibility = value;
    },
    [types.SET_COLUMN_SIZES](state, value) {
      state.column_sizes = value;
    },
    [types.SET_SCROLL_TOP](state, value) {
      state.scroll_top = value;
    },
    [types.SET_IS_REFETCHED](state, value) {
      state.isRefetched = value;
    },
    [types.SET_IS_UPDATING](state, value) {
      state.is_updating = value;
    }
  },
  actions: {
    setIsUpdating({ commit }, value) {
      commit(types.SET_IS_UPDATING, value);
    },
    updateTableConfig({ commit }, payload) {
      commit(types.UPDATE_TABLE_CONFIG, payload);
    },
    async fetchPage({ state, commit, dispatch, getters, rootGetters }) {
      try {
        dispatch('fetchSortingParams');

        const params = {
          type: getters.reportType(),
          filterId: rootGetters['filters/activeFilterId'],
          ...getTableConfigParams(state.tableConfig)
        };

        const response = await sharedReportApi.getReport(params);
        const data = response?.data;

        if (!data) {
          return;
        }

        commit(types.SET_TABLE, ReportTableAdapter(data));
        dispatch('setColumnSizes');
      } catch (e) {
        if (e?.code === slErrorCodes.INSUFFICIENT_ACCOUNT_PERMISSIONS) {
          if (!state.isRefetched) {
            commit(types.SET_IS_REFETCHED, true);

            await dispatch('fetchPage');
            commit(types.SET_IS_REFETCHED, false);

            return;
          }
        }

        this.dispatch('user/logout', { e, from: 'fetchPage' });
      }
    },
    async filterBy(_, { filterBy, ...body }) {
      try {
        const response = await sharedReportApi.filterBy(
          {
            type: filterTypesByRouteName[getRouteName()],
            filterBy
          },
          body
        );

        if (!response?.data) {
          return;
        }

        this.dispatch('filters/filter/setFilter', response.data);
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'filterBy' });
      }
    },
    async addToFilter({ rootGetters }, { filterBy, ...body }) {
      try {
        const response = await sharedReportApi.addToFilter(
          {
            filterId: rootGetters['filters/activeFilterId'],
            type: filterTypesByRouteName[getRouteName()],
            filterBy
          },
          body
        );

        if (!response?.data) {
          return;
        }

        this.dispatch('filters/filter/setFilter', response.data);
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'addToFilter' });
      }
    },
    setReportActiveFilterId({ commit, dispatch }, { id, isUpdate = false }) {
      this.dispatch('filters/setActiveFilterId', id);
      commit(types.UPDATE_TABLE_CONFIG, { key: 'page', value: 1 });
      commit(types.UPDATE_TABLE_CONFIG, { key: 'search', value: '' });

      if (isUpdate) {
        dispatch('fetchPage');
      }
    },
    async fetchSortingParams({ commit, getters, rootGetters }) {
      try {
        const response = await sortingApi.getSortingParams({
          type: getters.reportType(),
          filterId: rootGetters['filters/activeFilterId'],
          table: getters.reportTable()
        });
        const data = response?.data;

        const { sortOrder = '', columnKey = '' } = data || {};

        commit(types.UPDATE_TABLE_CONFIG, {
          key: 'sortOrder',
          value: sortOrder
        });

        commit(types.UPDATE_TABLE_CONFIG, {
          key: 'colClass',
          value: columnKey
        });
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'fetchSortingParams' });
      }
    },
    updateSortingParams({ rootGetters }, params) {
      return sortingApi.setSortingParams({
        ...params,
        filterId: rootGetters['filters/activeFilterId']
      });
    },
    async fetchColumnsVisibility({ commit, getters }) {
      try {
        const response = await sharedReportApi.getColumnsVisibility({
          type: getters.reportType()
        });

        commit(types.SET_COLUMNS_VISIBILITY, response.data);
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'fetchColumnsVisibility' });
      }
    },
    async updateColumnVisibility({ getters }, payload) {
      try {
        await sharedReportApi.postColumnVisibility({
          ...payload,
          type: getters.reportType()
        });
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'updateColumnVisibility' });
      }
    },
    async toggleColumnPin({ getters }, payload) {
      try {
        await sharedReportApi.toggleColumnPin({
          ...payload,
          type: getters.reportType()
        });
      } catch (e) {
        this.dispatch('user/logout', { e, from: 'updateColumnVisibility' });
      }
    },
    async updateColumnSizes(_, { columnKey, width }) {
      await this.dispatch('user/changeUiSettings', {
        key: uiSettingsKeys.COLUMNS_WIDTH,
        value: {
          clazz: columnKey,
          width
        }
      });

      this.dispatch('user/fetchUiSettings');
    },
    setColumnSizes({ commit, getters, rootState }) {
      const tab = uiTabByRoute[getRouteName()];
      const settings = rootState.user.ui_settings[tab]?.[uiSettingsKeys.COLUMNS_WIDTH] || [];
      const sizes = getResizedIndexesFromClasses(
        settings,
        getters.headerByColumnKeyMap
      );

      commit(types.SET_COLUMN_SIZES, sizes);
    },
    updateScrollTop({ commit }, value) {
      commit(types.SET_SCROLL_TOP, value);
    },
    exportTableToDatabase({ getters, rootGetters }) {
      const params = {
        filterId: rootGetters['filters/activeFilterId'],
        type: getters.reportType()
      };

      return sharedReportApi.exportTableToDatabase(params);
    },
    openTableLink(_, { search, route }) {
      const namespace = namespaceByRoute[route];

      this.dispatch(`${namespace}/setReportActiveFilterId`, { id: DEFAULT_FILTER_ID });
      this.dispatch(`${namespace}/updateTableConfig`, { key: 'search', value: search });

      projectRedirect({
        name: route,
        query: {
          ...router.currentRoute.query,
          search
        }
      });
    }
  },
  getters: {
    reportType: () => () => ordersTypesKeyMap[getRouteName()],
    reportTable: () => () => tableTypeKeyMap[getRouteName()],
    headerByColumnKeyMap: (state) => {
      const tableHeaders = [
        ...state.table.header.pinned.map((header) => ({ ...header, pinned: true })),
        ...state.table.header.default
      ];

      return tableHeaders.reduce((acc, header) => {
        acc[header.columnKey] = header;

        return acc;
      }, {});
    },
    columnByColumnKeyMap: (state) => {
      const tableColumns = [
        ...state.table.body.pinned.map((column) => ({ ...column, pinned: true })),
        ...state.table.body.default
      ];

      return tableColumns.reduce((acc, column) => {
        acc[column.columnKey] = column;

        return acc;
      }, {});
    }
  }
};