import { mapState, mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/file';
import { reportShortcuts } from '@/mixins/report/shortcuts.mixin';
import { treeShortcuts } from '@/mixins/demand/tree/shortcuts.mixin';
import { keyCodes } from '@/config/utils/statusCodes.config';
import { bomTypes } from '@/config/item/bom.config';
import { routeNames } from '@/config/router/router.config';
import modalsId from '@/config/shared/modalsId.config';
import { updateData, logLevel } from '@/config/project/updateData.config';
import { getCtrl } from '@/helpers/shared/listeners';
import { projectRedirect } from '@/helpers/router';

export const shortcut = {
  mixins: [modal, fileSaver, reportShortcuts, treeShortcuts],
  data() {
    return {
      reportRouteNames: [
        routeNames.INVENTORY,
        routeNames.INTERSITE,
        routeNames.REPORTS
      ],
      restrictedRouteNames: [
        routeNames.PROJECT_LIST,
        routeNames.ARCHIVE,
        routeNames.EXAMPLES
      ]
    };
  },
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId
    }),
    ...mapGetters({
      availableMainRoutes: 'access/availableMainRoutes',
      isTimeMachineActive: 'manageProjects/isTimeMachineActive',
      activeNodeId: 'demand/tree/activeNodeId'
    }),
    tabByKeycode() {
      if (!this.availableMainRoutes?.length) {
        return null;
      }

      return this.availableMainRoutes.reduce((acc, route, routeIndex) => {
        acc[keyCodes[routeIndex + 1]] = route;

        return acc;
      }, {});
    },
    currentHandler() {
      // look mixins/report/shortcuts.mixin
      if (this.$el.hasAttribute('data-shortcut-report')) {
        return this.handleReportShortcuts;
      }

      if (this.$el.hasAttribute('data-shortcut-tree')) {
        return this.handleTreeShortcuts;
      }

      if (this.$el.hasAttribute('data-shortcut-table')) {
        return this.handleTableShortcuts;
      }

      return this.handleGlobalShortcuts;
    }
  },
  mounted() {
    document.addEventListener('keydown', this.currentHandler);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.currentHandler);
  },
  methods: {
    ...mapActions({
      saveProject: 'project/saveProject',
      setBomEntry: 'bom/setSelectedEntry'
    }),
    isInput(e) {
      return ['input', 'textarea'].includes(e?.target?.tagName?.toLowerCase());
    },
    isTextSelected() {
      const selection = window.getSelection();

      return selection && selection.type === 'Range';
    },
    handleGlobalShortcuts(e) {
      if (this.restrictedRouteNames.includes(this.$sl_routeName) || this.isInput(e)) {
        return;
      }

      // open AI modal (ctrl + shift + a)
      if (getCtrl(e) && e.shiftKey && e.keyCode === keyCodes.a) {
        if (!this.$sl_isFeatureAvailable('sl_ai_access')) {
          return;
        }

        e.preventDefault();

        return this.showModal(modalsId.AI_ASSISTANT);
      }

      // save project (ctrl + s)
      if (getCtrl(e) && e.keyCode === keyCodes.s) {
        e.preventDefault();

        return this.saveProject();
      }

      // switch app tabs (alt + nums(1-9))
      if (e.altKey && e.keyCode >= keyCodes['1'] && e.keyCode <= keyCodes['9']) {
        e.preventDefault();

        const tabPath = this.tabByKeycode[e.keyCode];

        if (tabPath && !this.$route.path.includes(tabPath)) {
          return this.$router.push({
            name: this.tabByKeycode[e.keyCode]
          });
        }
      }

      // open entire BOM (ctrl + b)
      if (getCtrl(e) && e.keyCode === keyCodes.b && this.can.manageBom) {
        e.preventDefault();

        this.setBomEntry({
          route: routeNames.INVENTORY
        });

        return this.$router.push({
          name: routeNames.BILL_OF_MATERIALS,
          params: {
            type: bomTypes.ENTIRE
          }
        });
      }

      // freeze simulation modal (ctrl + shift + d)
      if (getCtrl(e) && e.shiftKey && e.keyCode === keyCodes.d) {
        e.preventDefault();

        return this.showModal(modalsId.FREEZE_SIMULATION);
      }

      // re-import data (ctrl + d)
      if (getCtrl(e) && e.keyCode === keyCodes.d) {
        e.preventDefault();

        return this.handleReImport(updateData.QUICK, logLevel.OFF);
      }

      // re-import data with logs (alt + shift + q)
      if (
        e.altKey && e.shiftKey && e.keyCode === keyCodes.q
        && this.can.updateProject
        && !this.isTimeMachineActive
        && this.isProjectActive
        && !this.$sl_isEditingRoute
      ) {
        return this.showModal(modalsId.UPDATE_WITH_LOGS);
      }

      // open logs page (ctrl + shift + q)
      if (getCtrl(e) && e.shiftKey && e.keyCode === keyCodes.q) {
        return projectRedirect({
          name: routeNames.LOGS_PAGE
        });
      }
    },
    handleTableShortcuts(e) {
      if (e.target.tagName !== 'BODY') {
        return;
      }

      if (getCtrl(e) && e.keyCode === keyCodes.a) {
        e.preventDefault();

        return this.handleSelectRowsAll(!this.headerCheckboxValue);
      }
    }
  }
};
