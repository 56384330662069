var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sl-text-area-wrapper",class:{
    'sl-text-area-wrapper__disabled': _vm.disabled
  }},[(_vm.label)?_c('div',{class:[{
      'sl-required': _vm.required
    }, 'sl-text-area__label body-1-md grey-80' ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"sl-text-area__input",class:{
      'sl-text-area--bottom-round': _vm.bottomRound
    }},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.vModel),expression:"vModel"},{name:"undo-redo",rawName:"v-undo-redo"}],ref:"input",staticClass:"sl-text-area body-1 grey-90",class:( _obj = {}, _obj[_vm.resizeClass] = true, _obj['sl-text-area--invalid'] =  _vm.isInvalid, _obj['sl-text-area--highlighted'] =  _vm.highlight, _obj ),style:(_vm.styles),attrs:{"data-test-id":_vm.dataTestId,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"autofocus":_vm.autofocus},domProps:{"value":(_vm.vModel)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();return _vm.handleTabPress($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }if(!$event.shiftKey){ return null; }if($event.ctrlKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();return _vm.handleShiftTabPress($event)}],"mousedown":_vm.syncSize,"keyup":_vm.syncSize,"&scroll":function($event){return _vm.syncScroll($event)},"input":function($event){if($event.target.composing){ return; }_vm.vModel=$event.target.value}}}),_vm._v(" "),(_vm.highlight)?_c('pre',{ref:"highlightedPre",staticClass:"sl-text-area__highlighted-text"},[_vm._v("      "),_c('code',{ref:"highlightedCode",staticClass:"hljs",domProps:{"innerHTML":_vm._s(_vm.highlightHtml)}}),_vm._v("\n    ")]):_vm._e(),(!_vm.isResizeOff)?_c('icon',{staticClass:"sl-text-area__resizer size-16",attrs:{"data":require("@icons/resizer.svg")}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }