import { mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/file';
import { routeNames } from '@/config/router/router.config';
import { bomTypes } from '@/config/item/bom.config';
import { namespaceByRoute } from '@/config/report';
import modalsId from '@/config/shared/modalsId.config';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { filterPageTypes } from '@/config/filters';
import { contextMenuFgs } from '@/config/shared/fgs.config';
import { copyToClipboard } from '@/helpers/utils/clipboard';
import { projectRedirect } from '@/helpers/router';
import { getNodeUrl } from '@/helpers/demand/tree';
import { openLink } from '@/helpers/shared/webAPI';
import { getFindInQuery } from '@/helpers/report/inventoryReport';

export const cellActions = {
  mixins: [modal, fileSaver],
  data() {
    return {
    };
  },
  provide() {
    return {
      openTableLink: this.openTableLink,
      openTreeNodeLink: this.openTreeNodeLink,
      openBomLink: this.openBomLink,
      substituteItem: this.substituteItem,
      removeFromImport: this.removeFromImport,
      copyItemCode: this.copyItemCode,
      selectAll: this.selectAll,
      openExplainCalculation: this.openExplainCalculation,
      truncateProject: this.truncateProject,
      processOverrideError: this.processOverrideError,
      openDemandNodeInNewTab: this.openDemandNodeInNewTab,
      openTableLinkInNewTab: this.openTableLinkInNewTab
    };
  },
  computed: {
    ...mapGetters({
      activeNodeId: 'demand/tree/activeNodeId',
      activeFilterId: 'filters/activeFilterId'
    }),
    reportNamespace() {
      return namespaceByRoute[this.$sl_routeName];
    },
    tableMetadata() {
      return this.$store.state[this.reportNamespace].table?.metadata || {};
    }
  },
  methods: {
    ...mapActions({
      fetchTreeByLink: 'demand/tree/fetchTreeByLink',
      fetchNodeIdByLink: 'demand/tree/fetchNodeIdByLink',
      setBomEntry: 'bom/setSelectedEntry',
      getItemClones: 'substitutions/getItemClones',
      updateRowsSelection: 'inventoryReport/updateRowsSelection',
      downloadTruncatedProject: 'project/downloadTruncatedProject',
      setFilterFieldByType: 'filters/setFilterFieldByType'
    }),
    openTableLink(payload) {
      return this.$store.dispatch(`${this.reportNamespace}/openTableLink`, payload);
    },
    openTableLinkInNewTab({ entity, route }) {
      const search = getFindInQuery(entity);
      const namespace = namespaceByRoute[route];

      this.$store.dispatch(`${namespace}/setReportActiveFilterId`, { id: DEFAULT_FILTER_ID });
      this.$store.dispatch(`${namespace}/updateTableConfig`, { key: 'search', value: search });

      const resolvedRoute = this.$router.resolve({
        name: route,
        query: {
          ...this.$router.currentRoute.query,
          search
        }
      });

      openLink(resolvedRoute.href, true);
    },
    async openTreeNodeLink({ metadata }) {
      await this.setFilterFieldByType({
        type: filterPageTypes.DEMAND,
        field: 'active_filter_id',
        value: DEFAULT_FILTER_ID
      });

      await projectRedirect({ name: routeNames.DEMAND });

      return this.fetchTreeByLink(metadata.entity);
    },
    async openDemandNodeInNewTab({ metadata }) {
      await this.setFilterFieldByType({
        type: filterPageTypes.DEMAND,
        field: 'active_filter_id',
        value: DEFAULT_FILTER_ID
      });

      const nodeId = await this.fetchNodeIdByLink(metadata.entity);

      openLink(getNodeUrl(nodeId), true);
    },
    async openBomLink({ metadata, cellValue }) {
      if (!this.can.manageBom || !(cellValue.enabledAcs & contextMenuFgs.ITEM_BOM)) {
        return;
      }

      await this.setBomEntry({
        ...metadata.entity,
        route: this.$route.name
      });

      await projectRedirect({
        name: routeNames.BILL_OF_MATERIALS,
        params: {
          type: bomTypes.ITEM
        }
      });
    },
    async substituteItem({ metadata }) {
      if (!this.can.manageSubstitutions) {
        return;
      }

      const { item } = metadata.entity;

      await this.getItemClones(item);

      this.showModal(modalsId.SUBSTITUTE_AN_ITEM, {
        activeItemCode: item
      });
    },
    async removeFromImport({ metadata }) {
      if (!this.can.manageSubstitutions) {
        return;
      }

      const { item } = metadata.entity;

      await this.getItemClones(item);

      this.showModal(modalsId.REMOVE_FROM_IMPORT, {
        activeItemCode: item
      });
    },
    copyItemCode({ metadata }) {
      copyToClipboard(metadata.entity.item || '');
    },
    selectAll() {
      if (this.tableMetadata.selectedRowsCount === this.tableMetadata.totalRowsCount) {
        return;
      }

      this.updateRowsSelection({
        state: true,
        global: true
      });
    },
    openExplainCalculation({ metadata, cellIndex, col }) {
      const { item, loc: location } = metadata.entity;

      this.showModal(modalsId.EXPLAIN_CALCULATION, {
        payload: { item, location },
        tableRowsNumber: col.cells.length,
        tableRowIndex: cellIndex
      });
    },
    truncateProject() {
      if (this.activeFilterId === DEFAULT_FILTER_ID) {
        return;
      }

      this.saveFile(this.downloadTruncatedProject.bind(this, {
        type: this.$store.getters[`${this.reportNamespace}/reportType`](),
        filterId: this.activeFilterId
      }));
    },
    processOverrideError({ e, isClear }) {
      const title = isClear
        ? this.$t('Web.Report.ClearOverridesError')
        : this.$t('Web.Report.ApplyOverridesError');

      if (e.metainfo) {
        return this.$notify({
          type: 'warn',
          title,
          text: e.message,
          duration: -1,
          data: {
            callback: () => this.showModal(modalsId.SL_TABLE_INFO_MODAL, {
              title,
              subtitle: e.message,
              table: e.metainfo
            })
          }
        });
      }

      this.$notify({
        type: 'error',
        title,
        text: e.message,
        duration: 10000
      });
    }
  }
};