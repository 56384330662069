import { routeNames } from '@/config/router/router.config';

export const filterPageTypes = {
  DEMAND: 'Demand',
  INVENTORY: 'Inventory',
  INTERSITE: 'Intersite',
  REPORT: 'Report',
  ANALYTICS: 'Analytics'
};

export const filterTypesByRouteName = {
  // main pages
  [routeNames.DEMAND]: filterPageTypes.DEMAND,
  [routeNames.INVENTORY]: filterPageTypes.INVENTORY,
  [routeNames.INTERSITE]: filterPageTypes.INTERSITE,
  [routeNames.REPORTS]: filterPageTypes.REPORT,
  [routeNames.DASHBOARD]: filterPageTypes.ANALYTICS,
  // subpages (links with report shared namespaces)
  [routeNames.INVENTORY_PLANNED_ORDERS]: filterPageTypes.INVENTORY,
  [routeNames.INVENTORY_IN_TRANSITION]: filterPageTypes.INVENTORY,
  [routeNames.COLLECTIONS]: filterPageTypes.INVENTORY,
  [routeNames.CONSTRAINED_PLAN]: filterPageTypes.INVENTORY,
  [routeNames.INTERSITE_PLANNED_ORDERS]: filterPageTypes.INTERSITE,
  [routeNames.INTERSITE_IN_TRANSITION]: filterPageTypes.INTERSITE,
  [routeNames.PROMOTIONS]: filterPageTypes.DEMAND,
  [routeNames.BATCHES_ON_HAND]: filterPageTypes.DEMAND
};