import { mapGetters, mapState } from 'vuex';
import {
  accessStateKeys,
  permissionByRouteName,
  defaultPermissions,
  defaultFeatures
} from '@/config/shared/access.config';

export const access = {
  computed: {
    ...mapState({
      _features: (state) => state.access.features || {},
      _permissions: (state) => state.access.permissions || {},
      _projectId: (state) => state.manageProjects.projectId
    }),
    ...mapGetters({
      _isSupportedPeriod: 'project/isSupportedPeriod'
    }),
    has() {
      const features = defaultFeatures();

      Object.keys(features).forEach(key => {
        features[key] = this._features[key];
      });

      return features;
    },
    can() {
      const states = Object.values(accessStateKeys);
      const permissions = defaultPermissions();

      Object.keys(permissions).forEach(key => {
        const value = this._permissions[key];

        if (typeof value === 'boolean') {
          Object.defineProperty(permissions, key, {
            get: () => value,
            enumerable: true
          });
        } else if (states.includes(value)) {
          Object.defineProperty(permissions, key, {
            get: () => ({
              edit: value === accessStateKeys.EDITABLE,
              view: value === accessStateKeys.EDITABLE || value === accessStateKeys.READ_ONLY,
              none: value === accessStateKeys.NONE
            }),
            enumerable: true
          });
        }
      });

      return permissions;
    },
    // use for route shared components such as reports
    isPageEditable() {
      const permission = this._permissions[permissionByRouteName[this.$sl_routeName]];

      if (typeof permission === 'string') {
        return permission === accessStateKeys.EDITABLE;
      }

      return permission;
    },
    isProjectActive() {
      return !!(this._projectId && this._isSupportedPeriod);
    }
  }
};
