import { groupBy as groupByKeys } from '@/config/connection';

export default function(data) {
  const { _user: user, _password: password } = data.settings.credentials;
  const {
    _itemInfo: itemInfo,
    _transactional: transactional,
    _toShip: toShip,
    _toReceive: toReceive,
    _bom: bom,
    _suppliers: suppliers,
    _substitutions: substitutions,
    _channelSubstitutions: channelSubstitutions,
    _channels: channels,
    _exportPO: exportPO,
    _exportTO: exportTO,
    _exportMO: exportMO,
    _expirations: expirations
  } = data.settings.endpoints;
  const {
    _combineLocs: combineLocs,
    _pageLimit: pageLimit,
    _allowUntrustedCert: allowUntrustedCert,
    _startDate: startDate,
    _groupBy: groupBy,
    _startFrom: startFrom
  } = data.settings.info;

  return {
    required: {
      user,
      password,
      transactional,
      itemInfo,
      toShip,
      toReceive,
      bom,
      suppliers,
      substitutions,
      channelSubstitutions,
      channels,
      exportPO,
      exportTO,
      exportMO,
      expirations,
      pageLimit
    },
    optional: {
      combineLocs: !!+combineLocs,
      allowUntrustedCert: !!+allowUntrustedCert
    },
    commonData: {
      startDate,
      groupBy,
      startFrom: groupBy === groupByKeys.MONTH ? +startFrom : +startFrom + 1,
      type: data._type
    }
  };
}
