import axios from '@/plugins/axios';

export default {
  getTables(params) {
    return axios.get('/integrations/netsuite/tables', { params });
  },
  getEntity(params) {
    return axios.get('/netsuite/entity', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  cacheEntity(params) {
    return axios.post('/netsuite/entity', null, { params });
  }
};