var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SubPageWrapper',{staticClass:"integrations",attrs:{"empty-cols":""},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('SlOverlay',{attrs:{"show":_vm.isLoading}})]},proxy:true},{key:"breadcrumbs",fn:function(){return [(_vm.isProjectEditing)?_c('SlBreadcrumb',{on:{"click":_vm.handleGoToDemand}},[_vm._v(" "+_vm._s(_vm.$t('Settings.TabNames.project'))+" ")]):_c('SlBreadcrumb',{on:{"click":_vm.handleGoToProjects}},[_vm._v(" "+_vm._s(_vm.$t('Main.Ui.btStartPageProjects'))+" ")]),_c('SlBreadcrumb',{attrs:{"index":1,"active":""}},[_vm._v(" "+_vm._s(_vm.projectName)+" ")])]},proxy:true},{key:"actions",fn:function(){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltip({
        content: _vm.$t('Web.Integrations.Tooltips.ImportDisabled'),
        disabled: _vm.canCreateProject
      })),expression:"getTooltip({\n        content: $t('Web.Integrations.Tooltips.ImportDisabled'),\n        disabled: canCreateProject\n      })"}],staticClass:"subpage__actions"},[(_vm.isProjectEditing)?_c('SlButton',{attrs:{"disabled":!_vm.canCreateProject},on:{"click":_vm.handleReimport}},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.Actions.BtnReimport'))+" ")]):_c('SlButton',{attrs:{"disabled":!_vm.canCreateProject},on:{"click":_vm.handleImport}},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.Actions.BtnImport'))+" ")])],1)]},proxy:true}])},[_c('SelectIntegrationModal'),_c('ContentBlock',[_c('div',{staticClass:"integrations__title heading-3-sb grey-100"},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.Title'))+" ")]),(!_vm.isProjectEditing)?_c('div',{staticClass:"integrations__block"},[_c('div',{staticClass:"integrations__block-content integrations__block-content--grouping-data"},[_c('SlControl',{attrs:{"options":_vm.groupByOptions,"label":_vm.$t('Web.Integrations.Form.GroupProjectBy')},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SlInfoButton',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getTooltip(_vm.$t('Web.Integrations.Tooltips.GroupProjectBy'))),expression:"getTooltip($t('Web.Integrations.Tooltips.GroupProjectBy'))",modifiers:{"bottom":true}}]})]},proxy:true}],null,false,2048532685),model:{value:(_vm.groupByModel),callback:function ($$v) {_vm.groupByModel=$$v},expression:"groupByModel"}}),(false)?_c('SlValidate',{key:"startFromDay",ref:"startFromProvider",attrs:{"vid":"startFromDay","rules":{
            required: true,
            between: {
              min: 1,
              max: 31,
              message: _vm.$t('Web.Validation.InvalidValue')
            }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"min":1,"max":31,"label":_vm.$t('Web.DbImport.LabelStartFromDay'),"is-invalid":invalid},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SlInfoButton',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getTooltip(_vm.$t('Web.DbImport.LabelDescrStartFrom'))),expression:"getTooltip($t('Web.DbImport.LabelDescrStartFrom'))",modifiers:{"bottom":true}}]})]},proxy:true}],null,true),model:{value:(_vm.startFromModel),callback:function ($$v) {_vm.startFromModel=$$v},expression:"startFromModel"}})]}}],null,false,2807237716)}):_vm._e(),(_vm.groupByNoMonth)?_c('SlSelect',{attrs:{"select-label":_vm.$t('Web.DbImport.LabelStartFrom'),"options":_vm.startFromOptions,"inline":""},model:{value:(_vm.startFromModel),callback:function ($$v) {_vm.startFromModel=$$v},expression:"startFromModel"}}):_vm._e()],1),_c('div',{staticClass:"integrations__block-content"},[(!_vm.isProjectEditing)?_c('SlCheckbox',{attrs:{"label":_vm.$t('DbImportDialog.Ui.chkStickLocationsTogether')},model:{value:(_vm.combineLocationsModel),callback:function ($$v) {_vm.combineLocationsModel=$$v},expression:"combineLocationsModel"}}):_c('SlInfoItem',{attrs:{"label":_vm.$t('DbImportDialog.Ui.chkStickLocationsTogether'),"value":_vm.combineLocationsValueText}})],1)]):_vm._e(),_c('div',{staticClass:"integrations__block"},[_c('div',{staticClass:"integrations__block-heading"},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.TitleImport'))+" ")]),_c('div',{staticClass:"integrations__block-content"},[(_vm.hasAggregation)?_c('IntegrationCard',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getTooltip({
            content: _vm.$t('Web.Integrations.Tooltips.AggregationDisabled'),
            disabled: !_vm.aggregationDisabled,
            style: 'width: 300px;'
          })),expression:"getTooltip({\n            content: $t('Web.Integrations.Tooltips.AggregationDisabled'),\n            disabled: !aggregationDisabled,\n            style: 'width: 300px;'\n          })",modifiers:{"bottom":true}}],key:_vm.aggregationItem.id,attrs:{"integration":_vm.aggregationItem,"disabled":_vm.aggregationDisabled},on:{"open":_vm.handleAggregationOpen}}):_vm._e(),_vm._l((_vm.importIntegrations),function(integration){return _c('IntegrationCard',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getIntegrationTooltip(integration)),expression:"getIntegrationTooltip(integration)",modifiers:{"bottom":true}}],key:integration.id,attrs:{"integration":integration,"nested":_vm.hasAggregation,"is-invalid":_vm.validated},on:{"open":_vm.handleSettingsOpen}})}),_c('SlButton',{on:{"click":function($event){return _vm.handleAddIntegration({
            module: _vm.integrationModuleTypes.IMPORT,
            restrict: _vm.isRestrictedFromAdding[_vm.integrationModuleTypes.IMPORT]
          })}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-20 color-white-stroke",attrs:{"data":require("@icons/plus.svg")}})]},proxy:true},(_vm.isRestrictedFromAdding[_vm.integrationModuleTypes.IMPORT])?{key:"append",fn:function(){return [_c('icon',{staticClass:"fill-off size-16",attrs:{"data":require("@icons/upgrade-plan.svg")}})]},proxy:true}:null],null,true)},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.Actions.BtnAddConnection'))+" ")])],2)]),_c('div',{staticClass:"integrations__block"},[_c('div',{staticClass:"integrations__block-heading"},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.TitleExport'))+" ")]),_c('div',{staticClass:"integrations__block-content"},[_c('div',{staticClass:"integrations__block-subheading"},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.TitleExportPO'))+" ")]),_vm._l((_vm.exportOrdersIntegrations),function(integration){return _c('IntegrationCard',{key:integration.id,attrs:{"integration":integration},on:{"open":_vm.handleSettingsOpen}})}),(!_vm.exportOrdersIntegrations.length)?_c('SlButton',{on:{"click":function($event){return _vm.handleAddIntegration({
            module: _vm.integrationModuleTypes.EXPORT,
            exportType: _vm.integrationExportTypes.PLANNED_ORDERS
          })}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-20 color-white-stroke",attrs:{"data":require("@icons/plus.svg")}})]},proxy:true}],null,false,3630437379)},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.Actions.BtnAddConnection'))+" ")]):_vm._e()],2),_c('div',{staticClass:"integrations__block-content"},[_c('div',{staticClass:"integrations__block-subheading"},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.TitleExportReports'))+" ")]),_vm._l((_vm.exportReportsIntegrations),function(integration){return _c('IntegrationCard',{key:integration.id,attrs:{"integration":integration},on:{"open":_vm.handleSettingsOpen}})}),(!_vm.exportReportsIntegrations.length)?_c('SlButton',{on:{"click":function($event){return _vm.handleAddIntegration({
            module: _vm.integrationModuleTypes.EXPORT,
            exportType: _vm.integrationExportTypes.REPORTS
          })}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('icon',{staticClass:"fill-off size-20 color-white-stroke",attrs:{"data":require("@icons/plus.svg")}})]},proxy:true}],null,false,3630437379)},[_vm._v(" "+_vm._s(_vm.$t('Web.Integrations.Actions.BtnAddConnection'))+" ")]):_vm._e()],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }