import { i18n } from '@/plugins/vue-i18n.plugin';
import spreadsheet from '@icon/connection/spreadsheet.svg';
import qb from '@icon/connection/qb.svg';
import amazon from '@icon/connection/amazon.svg';
import customApi from '@icon/connection/custom_api.svg';
import bc365 from '@icon/connection/bc365.svg';
import aggregation from '@icon/new/aggregation.svg';
import database from '@icon/connection/database.svg';
import sap from '@icon/connection/sap.svg';
import netsuite from '@icon/connection/oracle_netsuite.svg';
import dynamicsNav from '@icon/connection/dynamics_nav.svg';
import dynamicsGp from '@icon/connection/dynamics_gp.svg';
import cin7 from '@icon/connection/cin7.svg';
import cin7Core from '@icon/connection/cin7_core.svg';
import fishbowl from '@icon/connection/fishbowl.svg';
import spire from '@icon/connection/spire.svg';
import shopify from '@icon/connection/shopify.svg';

export const HIDE_LOADING_MODAL_TIMEOUT = 150;
export const TEXTAREA_SESSION_KEY = 'streamline.integrations.textareaHeight';

export const integrationStatuses = {
  UNINITIALIZED: 'Uninitialized',
  LOADING: 'Loading',
  IMPORTED: 'Imported',
  POPULATED: 'Populated',
  FAILED_TO_IMPORT: 'FailedToImport',
  VALIDATION_NEEDED: 'ValidationNeeded'
};

export const integrationModuleTypes = {
  IMPORT: 'importer',
  EXPORT: 'exporter'
};

export const integrationExportTypes = {
  PLANNED_ORDERS: 'orders',
  REPORTS: 'reports'
};

export const integrationTypes = {
  AGGREGATION: 'aggregation',
  SPREADSHEET: 'orderlist',
  QB_DESKTOP: 'qb',
  QB_ONLINE: 'qbo',
  AMAZON: 'amazon',
  CUSTOM_API: 'customapi',
  BC365: 'bc365',
  DATABASE: 'qdb',
  SAP: 'sapb1',
  SAP_API: 'sapb1Api',
  NETSUITE: 'netsuite',
  DYNAMICS_NAV: 'navdb',
  DYNAMICS_GP: 'gp',
  CIN7: 'cin7',
  CIN7CORE: 'cin7Core',
  FISHBOWL: 'fishbowl',
  SPIRE: 'spire',
  SHOPIFY: 'shopify'
};

export const databaseRelatedIntegrations = [
  integrationTypes.DATABASE,
  integrationTypes.SAP,
  integrationTypes.NETSUITE,
  integrationTypes.DYNAMICS_NAV,
  integrationTypes.DYNAMICS_GP,
  integrationTypes.FISHBOWL
];

export const predefinedIntegrations = [
  integrationTypes.QB_DESKTOP,
  integrationTypes.QB_ONLINE,
  integrationTypes.AMAZON,
  integrationTypes.BC365,
  integrationTypes.SAP,
  integrationTypes.NETSUITE,
  integrationTypes.DYNAMICS_NAV,
  integrationTypes.DYNAMICS_GP,
  integrationTypes.CIN7,
  integrationTypes.CIN7CORE,
  integrationTypes.FISHBOWL,
  integrationTypes.SPIRE,
  integrationTypes.SHOPIFY
];

export const connectionlessIntegrations = [
  integrationTypes.CUSTOM_API
];

export const connectionlessExportIntegrations = [
  integrationTypes.SAP,
  integrationTypes.DYNAMICS_NAV,
  integrationTypes.DYNAMICS_GP
];

// used in route params and locale
export const integrationSteps = {
  // shared between import and export
  CONNECT: 'connect',

  // import related only
  PULL_DATA: 'pull-data',
  TRANSFORM: 'transform',

  // export related only
  SETUP: 'setup',

  // spreadsheet related only
  UPLOAD_FILE: 'upload-file',
  MATCH_SHEETS: 'match-sheets'
};

export const stepStatuses = {
  INCOMPLETE: 'incomplete',
  COMPLETED: 'completed',
  NO_AVAILABLE: 'no-available'
};

export const integrationsConfig = {
  [integrationTypes.AGGREGATION]: {
    icon: aggregation
  },
  [integrationTypes.SPREADSHEET]: {
    icon: spreadsheet,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.UPLOAD_FILE]: {
          component: 'SpreadsheetUploadFile'
        },
        [integrationSteps.MATCH_SHEETS]: {
          component: 'SpreadsheetMatchSheets',
          tooltip: i18n.tc('Web.Integrations.Tooltips.MatchSheetsDisabled')
        },
        [integrationSteps.PULL_DATA]: {
          component: 'SpreadsheetPullData',
          isSavable: true
        }
      }
    }
  },
  [integrationTypes.QB_DESKTOP]: {
    icon: qb,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'QbConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'QbPullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'QbConnect'
        }
      }
    }
  },
  [integrationTypes.QB_ONLINE]: {
    icon: qb,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'QbOnlineConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'QbOnlinePullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'QbOnlineConnect'
        },
        [integrationSteps.SETUP]: {
          component: 'QbOnlineSetUp'
        }
      }
    }
  },
  [integrationTypes.AMAZON]: {
    icon: amazon,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'AmazonConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'AmazonPullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'AmazonConnect'
        }
      }
    }
  },
  [integrationTypes.BC365]: {
    icon: bc365,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'BC365Connect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'BC365PullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'BC365Connect'
        },
        [integrationSteps.SETUP]: {
          component: 'BC365PullData'
        }
      }
    }
  },
  [integrationTypes.CUSTOM_API]: {
    icon: customApi,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.PULL_DATA]: {
          component: 'CustomApiPullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.SETUP]: {
          component: 'CustomApiSetUp'
        }
      }
    }
  },
  [integrationTypes.DATABASE]: {
    icon: database,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'DatabaseConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'ImportTransform',
          isSavable: true
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'DatabaseConnect'
        },
        [integrationSteps.SETUP]: {
          component: 'ExportTransform'
        }
      }
    }
  },
  [integrationTypes.SAP]: {
    icon: sap,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'SapConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'ImportTransform',
          isSavable: true
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.SETUP]: {
          component: 'SapSetUp'
        }
      }
    }
  },
  [integrationTypes.SAP_API]: {
    icon: sap,
    steps: {
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.SETUP]: {
          component: 'SapApiSetUp'
        }
      }
    }
  },
  [integrationTypes.NETSUITE]: {
    icon: netsuite,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'NetsuiteConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'ImportTransform',
          isSavable: true
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'NetsuiteConnect'
        },
        [integrationSteps.SETUP]: {
          component: 'NetsuiteSetUp'
        }
      }
    }
  },
  [integrationTypes.DYNAMICS_NAV]: {
    icon: dynamicsNav,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'DynamicsNavConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'ImportTransform',
          isSavable: true
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.SETUP]: {
          component: 'DynamicsNavSetUp'
        }
      }
    }
  },
  [integrationTypes.DYNAMICS_GP]: {
    icon: dynamicsGp,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'DynamicsGpConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'ImportTransform',
          isSavable: true
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.SETUP]: {
          component: 'DynamicsGpSetUp'
        }
      }
    }
  },
  [integrationTypes.CIN7]: {
    icon: cin7,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'Cin7Connect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'Cin7PullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'Cin7Connect'
        }
      }
    }
  },
  [integrationTypes.CIN7CORE]: {
    icon: cin7Core,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'Cin7CoreConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'Cin7CorePullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'Cin7CoreConnect'
        }
      }
    }
  },
  [integrationTypes.FISHBOWL]: {
    icon: fishbowl,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'FishbowlConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'ImportTransform',
          isSavable: true
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'FishbowlConnect'
        }
      }
    }
  },
  [integrationTypes.SPIRE]: {
    icon: spire,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'SpireConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'SpirePullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      },
      [integrationModuleTypes.EXPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'SpireConnect'
        },
        [integrationSteps.SETUP]: {
          component: 'SpirePullData'
        }
      }
    }
  },
  [integrationTypes.SHOPIFY]: {
    icon: shopify,
    steps: {
      [integrationModuleTypes.IMPORT]: {
        [integrationSteps.CONNECT]: {
          component: 'ShopifyConnect'
        },
        [integrationSteps.PULL_DATA]: {
          component: 'ShopifyPullData'
        },
        [integrationSteps.TRANSFORM]: {
          component: 'ImportTransform'
        }
      }
    }
  }
};

export const TEMPLATE_PASSWORD = '********';
