import { mapActions } from 'vuex';
import { saveAs } from 'file-saver';
import { toggleUserInteraction } from '@/helpers/shared/webAPI';
import { getFileNameFromResponse } from '@/helpers/utils/fileUpload';
import { parse } from '@/helpers/utils/JSON';

export const fileSaver = {
  methods: {
    ...mapActions({
      subscribe: 'operations/subscribe'
    }),
    async saveFile(
      request,
      {
        errorTitle,
        errorText,
        notificationType = 'error'
      } = {}
    ) {
      try {
        toggleUserInteraction(true);

        const response = await request();

        const file = new File([response.data], getFileNameFromResponse(response));

        saveAs(file);
      } catch (error) {
        if (error.data instanceof Blob) {
          const reader = new FileReader();

          reader.onload = (event) => {
            const error = parse(event.target.result);
            const { message, description } = error;
            const errorMessage = message && description ? `${description}: ${message}` : '';

            return this.$notify({
              type: notificationType,
              title: errorTitle || this.$t('Main.Ui.acChooseExportToXlsx'),
              text: errorMessage || errorText || message || this.$t('ExportIssues.Ui.:Caption'),
              duration: -1
            });
          };

          reader.readAsText(error.data);

          return;
        }

        const message = error?.message;
        const description = error?.description;

        return this.$notify({
          type: notificationType,
          title: errorTitle || this.$t('Main.Ui.acChooseExportToXlsx'),
          text: errorText || message || description || this.$t('ExportIssues.Ui.:Caption'),
          duration: -1
        });
      } finally {
        toggleUserInteraction(false);
      }
    },
    async downloadFile({ subscriber, downloadRequest, errorConfig = {} }) {
      try {
        const { operationData } = await this.subscribe({ subscriber });

        await this.saveFile(
          downloadRequest.bind(this, { fileId: operationData.id }),
          errorConfig
        );
      } catch (e) {
        const errorMessage = e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            text: errorMessage,
            duration: 10000
          });
        }
      }
    }
  }
};
