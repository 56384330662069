import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import appcuesConfig from '@/config/plugins/appcues.config';
import { isProduction } from '@/helpers/shared/fronteggEnv';

function getAuthState() {
  return Vue.mapAuthState()?.authState;
}

async function fetchUserProfile(userId) {
  try {
    const response = await axios.get(`https://engagement.streamlineplan.com/appcues/api/v2/accounts/${appcuesConfig.ACCOUNT_ID}/users/${userId}/profile`, {
      headers: {
        'Authorization': `Basic ${appcuesConfig.API_TOKEN}`
      }
    });

    return response?.data;
  } catch (e) {
    console.error('Error fetching Appcues user profile:', e);
  }
}

function loadAppcues() {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.src = 'https://engagement.streamlineplan.com/appcues/216627.js';
    script.async = true;

    script.onload = () => resolve('Appcues loaded successfully.');
    script.onerror = () => reject(new Error('Error loading Appcues.'));

    document.head.appendChild(script);
  });
}

async function identify({ userId, email, tenantId, tenantName }) {
  try {
    await loadAppcues();
  } catch (e) {
    console.error(e);

    return;
  }

  const profile = await fetchUserProfile(email);

  window.Appcues.identify(
    email,
    {
      userId,
      tenantId,
      lastCsatRating: profile?.lastCsatRating || '-',
      streamlineLanguage: store.state.settings.language
    }
  );

  window.Appcues.group(
    tenantId,
    {
      tenantName
    }
  );
}

export async function initAppcues() {
  window.AppcuesSettings = { enableURLDetection: true };

  let authState = getAuthState();
  let { user, tenantsState } = authState;

  while (!user || !tenantsState?.tenants?.length) {
    await new Promise(res => setTimeout(() => {
      authState = getAuthState();

      user = authState.user;
      tenantsState = authState.tenantsState;

      res();
    }, 100));
  }

  const { id: userId, email, tenantId } = user;
  const { name: tenantName, metadata } = tenantsState.tenants.find(t => t.tenantId === tenantId);

  if (localStorage.getItem(appcuesConfig.LS_FLAG)) {
    return identify({
      userId,
      email,
      tenantId,
      tenantName
    });
  }

  const tenantMetadata = JSON.parse(metadata);
  const isCustomer = tenantMetadata.pipeline === appcuesConfig.CUSTOMER_PIPELINE;
  const isFreePlan = store.getters['account/isFreePlan'];

  if (!isProduction() || !(isCustomer || isFreePlan)) {
    return;
  }

  return identify({
    userId,
    email,
    tenantId,
    tenantName
  });
}