import { routeNames } from '@/config/router/router.config';
import { EMPTY_CACHE_OVERRIDE } from '@/config/shared/magicValues.config';

export const inTransitionRoute = {
  [routeNames.INVENTORY]: routeNames.INVENTORY_IN_TRANSITION,
  [routeNames.INTERSITE]: routeNames.INTERSITE_IN_TRANSITION
};

export const sourceByRoute = {
  [routeNames.INVENTORY_IN_TRANSITION]: {
    route: routeNames.INVENTORY,
    locale: 'Main.tabNames.inventory'
  },
  [routeNames.INTERSITE_IN_TRANSITION]: {
    route: routeNames.INTERSITE,
    locale: 'Main.tabNames.transfers'
  }
};

export const inTransitionKeys = {
  ARRIVAL: 'arrival',
  BACKORDER: 'backorder'
};

export const requestTypeByKey = {
  [inTransitionKeys.ARRIVAL]: 'arrivals',
  [inTransitionKeys.BACKORDER]: 'backorders'
};

export const exportTypeByKey = {
  [inTransitionKeys.ARRIVAL]: 'to_receive',
  [inTransitionKeys.BACKORDER]: 'to_ship'
};

export const inTransitionLabelCodeByType = {
  [inTransitionKeys.BACKORDER]: 'DbImportDialog.Ui.tabBackorders:at',
  [inTransitionKeys.ARRIVAL]: 'DbImportDialog.Ui.tabArrivals:at'
};

export const partOfForecastTypes = {
  YES: 'Yes',
  NO: 'No'
};

export const partOfForecastOptions = (ctx) => ([
  {
    text: ctx.$t('Common.Yes'),
    value: partOfForecastTypes.YES
  },
  {
    text: ctx.$t('Common.No'),
    value: partOfForecastTypes.NO
  }
]);

export const orderTypes = {
  NUll: EMPTY_CACHE_OVERRIDE,
  NONE: 'None',
  OTHER: 'Other',
  PURCHASE: 'Purchase',
  MANUFACTURE: 'Manufacture',
  TRANSFER: 'Transfer',
  SALES: 'Sales'
};

export const toReceiveOrderTypeOptions = (ctx) => ([
  {
    text: ctx.$t('Web.Edit.Reset'),
    value: orderTypes.NUll
  },
  {
    text: ctx.$t('Main.Lv.ByNone'),
    value: orderTypes.NONE
  },
  {
    text: ctx.$t('Main.OrderType.Purchase'),
    value: orderTypes.PURCHASE
  },
  {
    text: ctx.$t('Main.OrderType.Manufacture'),
    value: orderTypes.MANUFACTURE
  },
  {
    text: ctx.$t('Main.OrderType.Transfer'),
    value: orderTypes.TRANSFER
  }
]);

export const toShipOrderTypeOptions = (ctx) => ([
  {
    text: ctx.$t('Web.Edit.Reset'),
    value: orderTypes.NUll
  },
  {
    text: ctx.$t('Main.OrderType.Other'),
    value: orderTypes.OTHER
  },
  {
    text: ctx.$t('Main.OrderType.Manufacture'),
    value: orderTypes.MANUFACTURE
  },
  {
    text: ctx.$t('Main.OrderType.Transfer'),
    value: orderTypes.TRANSFER
  },
  {
    text: ctx.$t('Main.OrderType.Sales'),
    value: orderTypes.SALES
  }
]);

export const inTransitionConfig = (ctx) => ([
  {
    label: ctx.$t(inTransitionLabelCodeByType[inTransitionKeys.ARRIVAL]),
    value: inTransitionKeys.ARRIVAL
  },
  {
    label: ctx.$t(inTransitionLabelCodeByType[inTransitionKeys.BACKORDER]),
    value: inTransitionKeys.BACKORDER
  }
]);

export const inTransitionEntities = {
  ITEM: 'itemcode',
  LOCATION: 'location',
  CHANNEL: 'channel'
};

export const inTransitionInfoField = 'infoField';