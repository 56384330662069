import { mapActions, mapGetters, mapState } from 'vuex';
import * as Sentry from '@sentry/vue';
import axios from '@/plugins/axios';
import { AIAssistantAxios } from '@/plugins/axios/AIAssistant';
import { $amp_initAmplitude } from '@/plugins/product/amplitude';
import { initAppcues } from '@/plugins/product/appcues';
import {
  routeNames,
  noProjectAvailableRouteNames,
  systemRouteNames
} from '@/config/router/router.config';
import { projectStatuses } from '@/config/project';
import { componentNames } from '@/config/app.config.js';
import { licenseInvoiceDetails } from '@/config/license';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { noAccessReasons } from '@/config/router/noAccess.config';
import { systemRedirect } from '@/helpers/router';
import { dealWith } from '@/api/helpers/errorRegistry';
import { setLanguageHeader } from '@/helpers/api/axios';
import { sendRequests } from '@/helpers/lastChange/sendRequests.js';
import { CHAT_ID_SESSION_KEY } from '@/config/AIAssistant/AIAssistant.config';

export const initialization = {
  computed: {
    ...mapState({
      hasProject: (state) => state.initialization.hasProject,
      projectId: (state) => state.manageProjects.projectId,
      licenseStatus: state => state.account.license_details?.licenseStatus
    }),
    ...mapGetters({
      expiresIn: 'account/expiresIn',
      isGracePeriodFinished: 'account/isGracePeriodFinished',
      needLicenseDetails: 'account/needLicenseDetails',
      expirationInvoiceDate: 'account/expirationInvoiceDate',
      invoiceDueLocaleDate: 'account/invoiceDueLocaleDate',
      currentProjectMeta: 'manageProjects/currentProjectMeta'
    })
  },
  methods: {
    ...mapActions({
      setInitializing: 'initialization/setInitializing',
      getProjectStatus: 'initialization/getProjectStatus',
      getSubscriptionDetails: 'account/getSubscriptionDetails',
      getSystemVersion: 'project/getSystemVersion',
      pingProject: 'manageProjects/pingProject',
      getProjectList: 'manageProjects/getProjectList',
      setupCurrentProject: 'manageProjects/setupCurrentProject',
      setProjectId: 'manageProjects/setProjectId',
      getTenantUsers: 'initialization/getTenantUsers',
      getTenantInfo: 'account/getTenantInfo',
      fetchChatMessages: 'AIAssistant/fetchChatMessages',
      resetUserChat: 'AIAssistant/resetUserChat',
      initHealthCheckInterval: 'health/initHealthCheckInterval'
    }),
    async appInit() {
      try {
        this.setInitializing(true);
        this.initHealthCheckInterval();
        setLanguageHeader(this.$store.state?.settings?.language || 'en');

        const validTenant = await this.checkActiveTenant();

        if (!validTenant) {
          return;
        }

        const licenseOk = await this.checkLicense();

        if (!licenseOk) {
          return;
        }

        const hasProject = await this.checkProjectStatus();

        if (!hasProject) {
          return;
        }

        const isProjectOk = await this.checkProjectPing();

        if (!isProjectOk) {
          return;
        }

        await this.fetchCurrentProjectInfo();
        // await system redirect before frontegg empty redirect
        this.checkCurrentRoute();
        this.getTenantUsers();
        this.getSystemVersion();

        this.$sl_loadTeamMembers();
      } catch (e) {
        this.$store.dispatch('user/logout', { e, from: 'appInit' });
      } finally {
        this.setInitializing(false);

        this.postAppInit();
      }
    },
    postAppInit() {
      // sentry
      Sentry.setTag('tenant-id', this.authState.user.tenantId);

      Sentry.setUser({
        name: this.$sl_userName,
        email: this.$sl_userEmail
      });

      // frontegg
      this.loadEntitlements();

      // AI Assistant
      const sessionChatId = sessionStorage.getItem(CHAT_ID_SESSION_KEY);

      if (sessionChatId) {
        this.fetchChatMessages(sessionChatId);
      } else {
        this.resetUserChat(this.$sl_userId);
      }

      // amplitude
      $amp_initAmplitude();

      // appcues
      initAppcues();
    },
    async postActivationInit() {
      try {
        this.setProjectId(null);
        this.setInitializing(true);

        const licenseOk = await this.checkLicense();

        if (!licenseOk) {
          return;
        }

        const hasProject = await this.checkProjectStatus();

        if (!hasProject) {
          return;
        }

        await this.checkProjectPing();
      } catch (e) {
        this.$store.dispatch('user/logout', { e, from: 'postActivationInit' });
      } finally {
        this.setInitializing(false);
      }
    },
    async checkActiveTenant() {
      try {
        const response = await this.getTenantInfo();

        if (!response) {
          return;
        }

        const userTenantId = this.authState.user.tenantId;
        const serverTenantId = response?.data?.tenantId;

        if (!serverTenantId) {
          return systemRedirect({
            name: routeNames.ACTIVATION
          });
        }

        if (userTenantId === serverTenantId) {
          return true;
        }

        const userAvailableTenantIds = this.authState.user.tenantIds;

        if (!userAvailableTenantIds.includes(serverTenantId)) {
          return systemRedirect({
            name: routeNames.NO_ACCESS,
            query: {
              reason: noAccessReasons.WRONG_TENANT
            }
          });
        }

        await this.$sl_switchTenant(serverTenantId);

        return true;
      } catch (e) {
        this.$store.dispatch('user/logout', { e, from: 'checkActiveTenant' });
      }
    },
    async checkLicense() {
      try {
        await this.getSubscriptionDetails();

        if (!this.needLicenseDetails) {
          return true;
        }

        if (this.isGracePeriodFinished) {
          return systemRedirect({
            name: routeNames.NO_ACCESS,
            query: {
              reason: noAccessReasons.LICENSE_EXPIRED
            }
          });
        }

        if (licenseInvoiceDetails.includes(this.licenseStatus)) {
          this.$notify({
            type: 'warn',
            title: this.$t('Web.LicenseDetails.LicenseInvoiceExpiredIn.val', {
              p1: this.$tc('Web.LicenseDetails.LicenseInvoiceExpiredIn.p1', this.expirationInvoiceDate, { n: this.expirationInvoiceDate })
            }),
            text: this.$t('Web.LicenseDetails.LicenseInvoiceDetails', { 1: this.invoiceDueLocaleDate }),
            duration: 7000
          });
        } else if (this.expiresIn) {
          this.$notify({
            type: 'warn',
            title: (this.$t('Web.LicenseDetails.SubscriptionExpiresIn.val', {
              p1: this.$tc('Web.LicenseDetails.SubscriptionExpiresIn.p1', this.expiresIn, { n: this.expiresIn })
            })),
            text: this.$t('Web.LicenseDetails.ContactAdminToMaintain'),
            duration: 7000
          });
        }

        return true;
      } catch (e) {
        this.$store.dispatch('user/logout', { e, from: 'checkLicense' });
      }
    },
    async checkProjectStatus() {
      try {
        await this.getProjectStatus();

        if (!this.hasProject && !this.isNoProjectRoute()) {
          return systemRedirect({
            name: routeNames.GET_STARTED
          });
        }

        return this.hasProject;
      } catch (e) {
        this.$store.dispatch('user/logout', { e, from: 'checkProjectStatus' });
      }
    },
    async checkProjectPing() {
      try {
        const initRoute = this.$router.history?.pending;
        const pid = this.projectId || initRoute?.params?.projectId;

        if (!pid) {
          if (this.isNoProjectRoute()) {
            return systemRedirect(initRoute);
          }

          return systemRedirect({
            name: routeNames.PROJECT_LIST
          });
        }

        const isProjectOk = await this.pingProject({ pid });

        this.$store.dispatch('lastChanges/initLastChangeInterval');

        return isProjectOk;
      } catch (e) {
        dealWith({
          [slErrorCodes.PROJECT_NOT_FOUND]: {
            silent: true
          },
          '*': {
            text: (e) => e.message
          }
        })(e);

        this.$store.dispatch('user/logout', { e, from: 'checkProjectPing' });

        await systemRedirect({
          name: routeNames.PROJECT_LIST
        });
      }
    },
    checkCurrentRoute() {
      try {
        this.$router.onReady(() => {
          const route = this.$router.history?.current;
          const matchedRoute = this.$router.match(route);
          const systemRoute = systemRouteNames.includes(matchedRoute?.name);

          if (!matchedRoute?.name || systemRoute) {
            return systemRedirect({
              name: routeNames.PROJECT_LIST
            });
          }

          return this.$router.push(matchedRoute);
        });
      } catch (e) {
        this.$store.dispatch('user/logout', { e, from: 'checkCurrentRoute' });
      }
    },
    async fetchCurrentProjectInfo() {
      await this.getProjectList();

      if (this.currentProjectMeta?.status === projectStatuses.UNINITIALIZED) {
        return;
      }

      await Promise.allSettled([
        sendRequests(componentNames.ROOT),
        this.setupCurrentProject()
      ]);
    },
    setToken(token) {
      axios.defaults.headers.common['Authorization'] = token || '';
      AIAssistantAxios.defaults.headers.common['Authorization'] = token || '';
    },
    loginIfNeeded() {
      if (!this.authState.isAuthenticated) {
        this.$sl_loginWithRedirect();
      }
    },
    isNoProjectRoute() {
      const initRoute = this.$router.history?.pending;

      return noProjectAvailableRouteNames.includes(initRoute?.name);
    }
  }
};
