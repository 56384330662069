var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sl-dropzone-wrapper",class:{
    'sl-dropzone-wrapper--disabled': _vm.localDisabled,
    'sl-dropzone-wrapper--invalid': _vm.isInvalid
  }},[(_vm.label || _vm.description)?_c('div',{staticClass:"sl-dropzone-header"},[(_vm.label)?_c('div',{staticClass:"sl-dropzone-header__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.description)?_c('div',{staticClass:"sl-dropzone-header__description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      theme: 'dark',
      content: _vm.$t('Web.FileUpload.TooltipMaxFiles', { 1: _vm.tooltipMaxFiles || _vm.maxFiles }),
      disabled: !_vm.isMaxFilesReached
    }),expression:"{\n      theme: 'dark',\n      content: $t('Web.FileUpload.TooltipMaxFiles', { 1: tooltipMaxFiles || maxFiles }),\n      disabled: !isMaxFilesReached\n    }"}],staticClass:"sl-dropzone__input-wrapper"},[_c('vue-dropzone',{ref:"slDropzone",staticClass:"sl-dropzone",attrs:{"id":_vm.id,"options":_vm.dropzoneOptions,"use-custom-slot":""},on:{"vdropzone-file-added":_vm.onFileAdded,"vdropzone-removed-file":_vm.onFileRemoved}},[(_vm.$scopedSlots.content)?_vm._t("content"):_c('div',{staticClass:"sl-dropzone__content"},[_c('div',{staticClass:"sl-dropzone__content-icon"},[(!_vm.isInvalid)?_c('icon',{staticClass:"fill-off size-40",attrs:{"data":require("@icons/style_upload_cloud_double.svg")}}):_c('icon',{staticClass:"fill-off size-40",attrs:{"data":require("@icons/style_error_double.svg")}})],1),_c('div',{staticClass:"sl-dropzone__content-label"},[_c('SlButton',{attrs:{"variant":"text","color":"grey","disabled":_vm.localDisabled}},[_vm._v(" "+_vm._s(_vm.$t('Web.FileUpload.ClickToUpload'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('Web.FileUpload.DragAndDrop'))+" ")],1),_c('div',{staticClass:"sl-dropzone__accepted"},[(_vm.$scopedSlots.accepted)?_vm._t("accepted"):_vm._e()],2)])],2)],1),_c('div',{staticClass:"sl-dropzone__hidden-previews dropzone-previews"}),_c('transition',{attrs:{"name":"fade-down"}},[(_vm.uploads.length)?_c('div',{staticClass:"sl-dropzone__previews"},[_c('SlSwiperContainer',{attrs:{"slides-count":_vm.uploads.length}},_vm._l((_vm.uploads),function(ref,index){
    var file = ref.file;
    var uploading = ref.uploading;
    var successful = ref.successful;
    var error = ref.error;
return _c('SlSwiperSlide',{key:file && file.upload.uuid},[_c('SlDropzonePreviewItem',{attrs:{"file":file,"uploading":uploading,"successful":successful,"error":error},on:{"remove":_vm.handleRemoveFile,"reupload":function (file) { return _vm.handleReUploadFile(file, index); }}})],1)}),1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }