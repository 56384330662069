import { accessStateKeys, permissionByRouteName } from '@/config/shared/access.config';
import { mainRoutes } from '@/config/router/router.config';
import { getRouteName } from '@/helpers/shared/router';
import { projectRedirect } from '@/helpers/router';

const types = {
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_FEATURES: 'SET_FEATURES',
  RESET_STATE: 'RESET_STATE'
};

const defaultState = () => ({
  permissions: {},
  features: {}
});

const state = defaultState();

const getters = {
  isRouteAvailable: (state) => (routeName) => {
    const permission = state.permissions[permissionByRouteName[routeName]];

    if (permission === undefined) {
      return true;
    }

    if (typeof permission === 'string') {
      return permission !== accessStateKeys.NONE;
    }

    return permission;
  },
  availableMainRoutes: (_, getters) => mainRoutes.filter(getters.isRouteAvailable)
};

const mutations = {
  [types.SET_PERMISSIONS](state, value) {
    state.permissions = value;
  },
  [types.SET_FEATURES](state, value) {
    state.features = value;
  },
  [types.RESET_STATE](state) {
    const initialState = defaultState();

    Object.keys(state).forEach(key => {
      state[key] = initialState[key];
    });
  }
};

const actions = {
  async setUserPermissions({ commit, dispatch }, permissions) {
    try {
      commit(types.SET_PERMISSIONS, permissions);
      dispatch('checkRouteAvailability');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'setUserPermissions' });
      throw e;
    }
  },
  setProjectFeatures({ commit }, features) {
    commit(types.SET_FEATURES, Object.fromEntries(features.map(key => [key, true])));
  },
  checkRouteAvailability({ getters }) {
    const routeName = getRouteName();

    if (!routeName) {
      return;
    }

    if (!getters.isRouteAvailable(routeName)) {
      projectRedirect({
        name: getters.availableMainRoutes[0]
      });
    }
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
