<template>
  <SlDropdown
    v-bind="$attrs"
    placement="bottom-end"
  >
    <template #target>
      <SlButton
        variant="tertiary"
        color="grey"
        size="xs"
        :disabled="!allowedActions.length"
        icon
      >
        <icon
          data="@icons/vertical-dots.svg"
          class="size-20 color-grey"
        />
      </SlButton>
    </template>
    <template #content>
      <SlDropdownOption
        v-for="action in allowedActions"
        :key="action.flag"
        select-close
        @select="action.action(integration)"
      >
        <template #prepend>
          <icon
            v-if="action.icon"
            class="fill-off size-16 stroke-icon--grey"
            :data="require(`@icons/${action.icon}.svg`)"
          />
        </template>
        {{ action.label }}
      </SlDropdownOption>
    </template>
  </SlDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'IntegrationActionsDropdown',
  mixins: [modal],
  props: {
    actions: {
      type: Number,
      required: true
    },
    integration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      actionsConfig: [
        {
          flag: 0,
          label: this.$t('Web.Integrations.Actions.BtnSettings'),
          icon: 'setting',
          action: this.handleOpenSettings
        },
        {
          flag: 1,
          label: this.$t('Web.Integrations.Actions.BtnRename'),
          icon: 'pen',
          action: this.handleRename
        },
        {
          flag: 2,
          label: this.$t('Web.Integrations.Actions.BtnDelete'),
          icon: 'trash',
          action: this.handleDelete
        }
      ]
    };
  },
  inject: ['handleSettingsOpen'],
  computed: {
    ...mapGetters({
      importIntegrations: 'integrations/importIntegrations',
      existingIntegrationNames: 'integrations/existingIntegrationNames'
    }),
    allowedActions() {
      return this.actionsConfig.filter(action => this.actions & 1 << action.flag);
    }
  },
  methods: {
    ...mapActions({
      renameIntegration: 'integrations/renameIntegration',
      deleteIntegration: 'integrations/deleteIntegration'
    }),
    handleOpenSettings() {
      this.handleSettingsOpen(this.integration);

      ampli.connectionSettingsButtonClicked({
        connectorName: this.integration.name,
        connectorType: this.integration.type
      });
    },
    handleRename() {
      this.showModal(modalsId.SL_RENAME_MODAL, {
        text: this.integration.name,
        title: this.$t('Web.Modals.Rename.Title', { 1: this.$t('Web.Modals.Rename.Connection') }),
        validationRules: {
          'sl_not_includes': {
            values: this.existingIntegrationNames(this.integration.module).filter(name => name !== this.integration.name),
            message: this.$t('Web.Error.AlreadyExist', {
              1: this.$t('Web.Error.Names.Connection')
            })
          }
        },
        saveCallback: (name) => this.renameIntegration({
          id: this.integration.id,
          module: this.integration.module,
          name
        })
      });
    },
    handleDelete() {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.Delete.Title', { 1: this.integration.name }),
        text: this.importIntegrations.length === 2
          ? this.$t('Web.Integrations.Modals.TextAffectedAggregation')
          : this.$t('Web.Modals.Delete.Description'),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.BtnDelete'),
        confirmCallback: () => {
          ampli.connectionDeleted({
            connectorName: this.integration.name,
            connectorType: this.integration.type
          });

          this.deleteIntegration({
            id: this.integration.id,
            module: this.integration.module
          });
        }
      });
    }
  }
};
</script>
