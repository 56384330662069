<template>
  <SlModal
    :id="id"
    :title="modalTitle"
    :loading="isLoading"
    :width="800"
    persistent
    @created="onCreated"
    @show="onModalShow"
    @hide="onModalHide"
  >
    <div class="modal-content create-integration">
      <template v-if="isOptionsGrouped">
        <div
          v-for="(integrationGroup, index) in options"
          :key="integrationGroup.group"
          class="create-integration__group"
        >
          <div
            v-if="index !== 0"
            class="create-integration__group-title body-4-md grey-60"
          >
            <span class="body-4-md grey-60 mi-8">{{ integrationGroup.group }}</span>
          </div>
          <div class="create-integration__items">
            <IntegrationTile
              v-for="integration in integrationGroup.integrations"
              :key="integration.sl_id"
              :integration="integration"
              :disabled="!integration.availableForCurrentPlan"
              @select="handleIntegrationSelect"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="create-integration__items">
          <IntegrationTile
            v-for="integration in options"
            :key="integration.sl_id"
            :integration="integration"
            :disabled="!integration.availableForCurrentPlan"
            @select="handleIntegrationSelect"
          />
        </div>
      </template>
    </div>
  </SlModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IntegrationTile from '@/components/Integrations/IntegrationTile.vue';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { integrationExportTypes } from '@/config/integrations';

export default {
  name: 'SelectIntegrationModal',
  components: {
    IntegrationTile
  },
  mixins: [modal],
  data() {
    return {
      id: modalsId.SELECT_INTEGRATION,
      module: null,
      exportType: null,
      selectCallback: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      availableIntegrations: state => state.integrations.available_integrations
    }),
    modalTitle() {
      if (this.exportType === integrationExportTypes.PLANNED_ORDERS) {
        return this.$t('Web.Integrations.ConnectionTitle', { 1: this.$t('Web.Integrations.TitleExportPO') });
      }

      if (this.exportType === integrationExportTypes.REPORTS) {
        return this.$t('Web.Integrations.ConnectionTitle', { 1: this.$t('Web.Integrations.TitleExportReports') });
      }

      return this.$t('Web.Integrations.Modals.TitleChooseDataSource');
    },
    options() {
      return this.availableIntegrations[this.module]?.filter(option => !option.group || option.integrations.length);
    },
    isOptionsGrouped() {
      return this.options?.some(integration => integration.group);
    }
  },
  methods: {
    ...mapActions({
      fetchAvailableIntegrations: 'integrations/fetchAvailableIntegrations'
    }),
    async onModalShow() {
      try {
        this.isLoading = true;

        await this.fetchAvailableIntegrations({
          module: this.module,
          exportType: this.exportType
        });
      } finally {
        this.isLoading = false;
      }
    },
    onModalHide() {
      this.module = null;
      this.exportType = null;
      this.selectCallback = null;
      this.isLoading = false;
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    async handleIntegrationSelect({ id, name, type }) {
      try {
        this.isLoading = true;

        await this.selectCallback({
          id,
          name,
          type,
          module: this.module,
          exportType: this.exportType
        });

        this.hideModal(this.id);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/utils";

.mi-8 {
  margin-inline: 8px;
}

.create-integration {
  &__group:not(:last-child) {
    margin-bottom: 24px;
  }

  &__group-title {
    display: flex;
    align-items: center;

    padding-inline: 8px;
    margin-bottom: 24px;

    &::before,
    &::after {
      content: "";
      flex-grow: 1;
      border-bottom: 1px solid $grey-30;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 240px);
    gap: 16px;
  }
}
</style>
