import axios from '@/plugins/axios';

export default {
  getTree(params) {
    return axios.get('demand/tree', { params });
  },
  getTreeChild(params) {
    return axios.get('demand/tree/child', { params });
  },
  collapseTreeChild(params) {
    return axios.delete('demand/tree/child', { params });
  },
  getTreeByLink(params) {
    return axios.get('demand/tree/link', { params });
  },
  getNodePath(params) {
    return axios.get('demand/tree/path', { params });
  },
  searchTreeNodes(params) {
    return axios.get('demand/tree/search', { params });
  },
  postSettings(data, params) {
    return axios.post('/settings', data, { params });
  },
  getTreeCategories() {
    return axios.get('demand/tree/categories_headers');
  },
  changeTreeSettings(body, params) {
    return axios.post('demand/tree/ui-settings', body, { params });
  },
  toggleApproveState(body) {
    return axios.post('demand/tree/approve', body);
  },
  toggleNodeState(params) {
    return axios.post('demand/tree/node_state', null, { params });
  }
};
