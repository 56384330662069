<template>
  <div
    class="sl-text-area-wrapper"
    :class="{
      'sl-text-area-wrapper__disabled': disabled
    }"
  >
    <div
      v-if="label"
      :class="[{
        'sl-required': required
      }, 'sl-text-area__label body-1-md grey-80',
      ]"
    >
      {{ label }}
    </div>
    <div
      class="sl-text-area__input"
      :class="{
        'sl-text-area--bottom-round': bottomRound
      }"
    >
      <textarea
        ref="input"
        v-model="vModel"
        v-undo-redo
        :data-test-id="dataTestId"
        :placeholder="placeholder"
        :disabled="disabled"
        :autofocus="autofocus"
        :style="styles"
        class="sl-text-area body-1 grey-90"
        :class="{
          [resizeClass]: true,
          'sl-text-area--invalid': isInvalid,
          'sl-text-area--highlighted': highlight
        }"
        @keydown.enter.stop
        @keydown.tab.exact.prevent="handleTabPress"
        @keydown.shift.tab.exact.prevent="handleShiftTabPress"
        @mousedown="syncSize"
        @keyup="syncSize"
        @scroll.passive="syncScroll"
      />
      <pre
        v-if="highlight"
        ref="highlightedPre"
        class="sl-text-area__highlighted-text"
      >
        <code
          ref="highlightedCode"
          class="hljs"
          v-html="highlightHtml"
        />
      </pre>
      <icon
        v-if="!isResizeOff"
        data="@icons/resizer.svg"
        class="sl-text-area__resizer size-16"
      />
    </div>
  </div>
</template>

<script>
import hljs from 'highlight.js/lib/core';

export default {
  name: 'SlTextArea',
  props: {
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '70'
    },
    resizeVariant: {
      type: String,
      default: 'vertical'
    },
    dataTestId: {
      type: [String, null],
      default: null
    },
    highlight: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    autofocus: Boolean,
    bottomRound: Boolean,
    required: Boolean,
    isInvalid: Boolean
  },
  data() {
    return {
      heightDifference: 2
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    styles() {
      if (!this.height) {
        return '';
      }

      return this.height === 'auto'
        ? 'height: auto'
        : `height: ${this.height}px`;
    },
    highlightHtml() {
      return hljs.highlight(
        this.vModel,
        { language: 'sql' }
      ).value;
    },
    resizeClass() {
      return `sl-text-area--resize-${this.resizeVariant}`;
    },
    isResizeOff() {
      return this.resizeVariant === 'none';
    }
  },
  methods: {
    addSingleTab(e, text, selectionStart, selectionEnd) {
      this.vModel = text.slice(0, selectionStart) + '\t' + text.slice(selectionEnd);

      this.$nextTick(() => {
        e.target.focus();
        e.target.setSelectionRange(selectionStart + 1, selectionStart + 1);
      });
    },
    removeSingleTab(e, text, selectionStart) {
      if (selectionStart > 0 && text.charAt(selectionStart - 1) === '\t') {
        this.vModel = text.slice(0, selectionStart - 1) + text.slice(selectionStart);

        this.$nextTick(() => {
          e.target.focus();
          e.target.setSelectionRange(selectionStart - 1, selectionStart - 1);
        });
      }
    },
    getIndentedLines(lines) {
      return lines.map(line => '\t' + line);
    },
    getOutdentedLines(lines) {
      return lines.map(line => line.startsWith('\t') ? line.slice(1) : line);
    },
    updateMultipleLines(e, textBefore, linesBetween, textAfter) {
      const newTextBetween = linesBetween.join('\n');

      this.vModel = textBefore + newTextBetween + textAfter;

      this.$nextTick(() => {
        e.target.focus();

        e.target.setSelectionRange(
          textBefore.length,
          textBefore.length + newTextBetween.length
        );
      });
    },
    handleTabPress(e) {
      const { selectionStart, selectionEnd } = e.target;
      const text = this.vModel;

      if (selectionStart === selectionEnd) {
        this.addSingleTab(e, text, selectionStart, selectionEnd);

        return;
      }

      const {
        textBefore,
        linesBetween,
        textAfter
      } = this.splitSelection(text, selectionStart, selectionEnd);

      const indentedLines = this.getIndentedLines(linesBetween);

      this.updateMultipleLines(e, textBefore, indentedLines, textAfter);
    },
    handleShiftTabPress(e) {
      const { selectionStart, selectionEnd } = e.target;
      const text = this.vModel;

      if (selectionStart === selectionEnd) {
        this.removeSingleTab(e, text, selectionStart);

        return;
      }

      const {
        textBefore,
        linesBetween,
        textAfter
      } = this.splitSelection(text, selectionStart, selectionEnd);

      const outdentedLines = this.getOutdentedLines(linesBetween);

      this.updateMultipleLines(e, textBefore, outdentedLines, textAfter);
    },
    splitSelection(text, selectionStart, selectionEnd) {
      let lineStartIndex = text.lastIndexOf('\n', selectionStart - 1);

      if (lineStartIndex === -1) {
        lineStartIndex = 0;
      } else {
        lineStartIndex++;
      }

      let lineEndIndex = text.indexOf('\n', selectionEnd);

      if (lineEndIndex === -1) {
        lineEndIndex = text.length;
      }

      const textBefore = text.slice(0, lineStartIndex);
      const textBetween = text.slice(lineStartIndex, lineEndIndex);
      const textAfter = text.slice(lineEndIndex);

      return {
        textBefore,
        linesBetween: textBetween.split('\n'),
        textAfter
      };
    },
    syncSize(e) {
      if (!this.highlight) {
        return;
      }

      const highlightedCode = this.$refs.highlightedCode;

      if (highlightedCode) {
        if (e.target.scrollHeight > (e.target.offsetHeight - this.heightDifference)) {
          highlightedCode.style.height = e.target.scrollHeight + 'px';
        } else {
          highlightedCode.style.height = 'fit-content';
        }

        this.syncScroll(e);
      }
    },
    syncScroll(e) {
      if (!this.highlight) {
        return;
      }

      const highlightedPre = this.$refs.highlightedPre;

      if (highlightedPre) {
        highlightedPre.scrollTop = e.target.scrollTop;
        highlightedPre.scrollLeft = e.target.scrollLeft;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-text-area';
</style>
