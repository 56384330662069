export const dataFields = {
  CONNECTION_DATA: 'connection_data',
  CONNECTION_DATA_CACHE: 'connection_data_cache'
};

export const tabKeys = {
  // settings
  REQUIRED: 'required',
  EXPORT: 'export',
  OPTIONAL: 'optional',
  // import
  TRANSACTIONS: 'transactions',
  ITEM_INFO: 'inventory',
  CHANNEL_INFO: 'channels',
  ORDERS_RECEIVE: 'arrivals',
  ORDERS_SHIP: 'backorders',
  SUBSTITUTIONS: 'substitutions',
  BOM: 'bom',
  PROMOTIONS: 'promotions',
  BATCH_EXPIRATIONS: 'expirations',
  INVENTORY_PARAMETERS: 'inventoryParameters',
  SUPPLIERS: 'suppliers',
  TRANSFER_REGIONS: 'transferRegions',
  // export
  PLANNED_ORDERS: 'planned_orders',
  MIN_MAX: 'minmax',
  INVENTORY_REPORT: 'inventory_report',
  INVENTORY_FORECAST: 'forecast_report',
  REPORTS: 'reports'
};

export const netsuiteTabKeys = [
  tabKeys.TRANSACTIONS,
  tabKeys.ITEM_INFO,
  tabKeys.ORDERS_RECEIVE,
  tabKeys.ORDERS_SHIP,
  tabKeys.BOM,
  tabKeys.SUPPLIERS
];

export const importKeysOrder = {
  [tabKeys.TRANSACTIONS]: 1,
  [tabKeys.ITEM_INFO]: 2,
  [tabKeys.ORDERS_RECEIVE]: 3,
  [tabKeys.ORDERS_SHIP]: 4,
  [tabKeys.PROMOTIONS]: 5,
  [tabKeys.SUBSTITUTIONS]: 6,
  [tabKeys.BATCH_EXPIRATIONS]: 7,
  [tabKeys.BOM]: 8,
  [tabKeys.INVENTORY_PARAMETERS]: 9,
  [tabKeys.CHANNEL_INFO]: 10,
  [tabKeys.SUPPLIERS]: 11,
  [tabKeys.TRANSFER_REGIONS]: 12
};

export const exportKeysOrder = {
  [tabKeys.MIN_MAX]: 1,
  [tabKeys.PLANNED_ORDERS]: 2,
  [tabKeys.INVENTORY_FORECAST]: 3,
  [tabKeys.INVENTORY_REPORT]: 4,
  [tabKeys.REPORTS]: 5
};

export const importTabKeys = [
  tabKeys.TRANSACTIONS,
  tabKeys.ITEM_INFO,
  tabKeys.CHANNEL_INFO,
  tabKeys.ORDERS_RECEIVE,
  tabKeys.ORDERS_SHIP,
  tabKeys.SUBSTITUTIONS,
  tabKeys.BOM,
  tabKeys.PROMOTIONS,
  tabKeys.BATCH_EXPIRATIONS,
  tabKeys.INVENTORY_PARAMETERS,
  tabKeys.SUPPLIERS,
  tabKeys.TRANSFER_REGIONS
];

export const exportTabKeys = [
  tabKeys.PLANNED_ORDERS,
  tabKeys.MIN_MAX,
  tabKeys.INVENTORY_REPORT,
  tabKeys.INVENTORY_FORECAST,
  tabKeys.REPORTS
];

export const settingTabs = (ctx) => ([
  {
    label: ctx.$t('Web.BaseConnectors.Tabs.Connection'),
    title: ctx.$t('Web.BaseConnectors.Tabs.Subtitle.Connection'),
    value: tabKeys.REQUIRED,
    component: 'Required',
    required: true
  },
  {
    label: ctx.$t('Web.DbRelatedConnectors.Tabs.Export'),
    title: ctx.$t('Web.DbRelatedConnectors.Tabs.Subtitle.Export'),
    value: tabKeys.EXPORT,
    component: 'Export'
  },
  {
    label: ctx.$t('Web.BaseConnectors.Tabs.Advanced'),
    title: ctx.$t('Web.BaseConnectors.Tabs.Subtitle.Advanced'),
    value: tabKeys.OPTIONAL,
    component: 'Optional'

  }
]);

export const importTabs = (ctx) => ([
  {
    label: ctx.$t('DbImportDialog.Ui.tabMainQuery:at'),
    value: tabKeys.TRANSACTIONS,
    component: 'Transactions'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabItemQry:at'),
    value: tabKeys.ITEM_INFO,
    component: 'ItemInfo'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabChannelsInfo:at'),
    value: tabKeys.CHANNEL_INFO,
    component: 'ChannelInfo'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabArrivals:at'),
    value: tabKeys.ORDERS_RECEIVE,
    component: 'OrdersToReceive'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabBackorders:at'),
    value: tabKeys.ORDERS_SHIP,
    component: 'OrdersToShip'
  },
  {
    label: ctx.$t('Web.CustomApi.Input.Substitutions'),
    value: tabKeys.SUBSTITUTIONS,
    component: 'Substitutions'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabBomQuery:at'),
    value: tabKeys.BOM,
    component: 'BOM'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabPromotions:at'),
    value: tabKeys.PROMOTIONS,
    component: 'Promotions'
  },
  {
    label: ctx.$t('DbImportDialog.Ui.tabBatches:at'),
    value: tabKeys.BATCH_EXPIRATIONS,
    component: 'Expirations'
  },
  {
    label: ctx.$t('Web.DbImport.TabProjected'),
    value: tabKeys.INVENTORY_PARAMETERS,
    component: 'InventoryParameters'
  },
  {
    label: ctx.$t('Web.Suppliers.PageTitle'),
    value: tabKeys.SUPPLIERS,
    component: 'Suppliers'
  },
  {
    label: ctx.$t('Web.TransferRegions.PageTitle'),
    value: tabKeys.TRANSFER_REGIONS,
    component: 'TransferRegions'
  }
]);

export const exportTabs = (ctx) => ([
  {
    label: ctx.$t('Web.DbImport.TabPlannedOrders'),
    value: tabKeys.PLANNED_ORDERS,
    component: 'PlannedOrders'
  },
  {
    label: ctx.$t('Web.DbImport.TabMinMax'),
    value: tabKeys.MIN_MAX,
    component: 'MinMax'
  },
  {
    label: ctx.$t('Web.DbImport.TabInvReport'),
    value: tabKeys.INVENTORY_REPORT,
    component: 'InventoryReport'
  },
  {
    label: ctx.$t('Web.DbImport.TabInvForecast'),
    value: tabKeys.INVENTORY_FORECAST,
    component: 'InventoryForecast'
  },
  {
    label: ctx.$t('Web.DbImport.TabReports'),
    value: tabKeys.REPORTS,
    component: 'Reports'
  }
]);
